import React from 'react';
import styled from 'styled-components';
import { ItemTypeImages } from 'constants/index';
import { ApiItemType, ItemType } from 'components/Item/types';
import colors from 'constants/colors';
import { SubHeading } from 'components/Type';

export const typeIconSize = {
  [ApiItemType.POPUP]: { width: 16, height: 16 },
  [ApiItemType.INTERACTIVE]: { width: 11, height: 16 },
  [ApiItemType.VIDEO]: { width: 12, height: 14 },
  //[ApiItemType.FOOTER_LINK]: { width: 1, height: 1 },
};

type Props = {
  type: ApiItemType;
};

const SubHeadingIcon: React.FunctionComponent<Props> = (props) => (
  <Container>
    <Red>|</Red>
    {/* <Icon src={ItemTypeImages[props.type]} width={typeIconSize[props.type].width} height={typeIconSize[props.type].height} /> */}
    <Icon src={ItemTypeImages[props.type]} />
  </Container>
);

export default SubHeadingIcon;

const Container = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Red = styled(SubHeading)`
  color: ${colors.red};
  display: inline-block;
  margin: 0 5px;
`;

const Icon = styled.img`
  max-height: 100%;
  flex: 1;
  width: 0.5vw;
  min-width: 13px;
  display: block;
`;
