import mobile from 'constants/mobile';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import styled from 'styled-components';

// original 1920x167

const RATIO = 167 / 1920;

const HeaderGradient: React.FunctionComponent = () => {
  const width = useWindowSize().width;
  const height = width > 1920 ? 167 : Math.ceil(width * RATIO);
  return <Container height={height} />;
};

const Container = styled.div<{ height: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ height }) => height}px;
  pointer-events: none;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  z-index: 1;
  opacity: 0.76;
  pointer-events: none;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: none;
  }
`;

export default HeaderGradient;
