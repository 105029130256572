import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import capabilities, { tradeShows } from 'constants/capabilities';
import { ScaledSubHeading, SubHeading } from 'components/Type';
import CapabilityCarousel from 'components/CapabilityCarousel';
import { sortBy } from 'lodash';
import { ApiCapability } from './types';

type Props = {
  capabilityId?: string;
  hideInfo?: boolean;
  hideHeading?: boolean;
  isTradeShow?: boolean;

  subHeading: string;
  parentCapability?: ApiCapability;
  items: ApiCapability[];
};

const Capabilities: React.FunctionComponent<Props> = ({ capabilityId, hideInfo, hideHeading, isTradeShow, items, subHeading }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useLayoutEffect(() => {
    const measure = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  });

  return (
    <Container ref={ref}>
      {!hideHeading && <ScaledSubHeading>{subHeading}</ScaledSubHeading>}
      {!!items && <CapabilityCarousel items={items} width={width} hideInfo={hideInfo} />}
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  position: relative;
  min-width: 0;
`;

export default Capabilities;
