import React, { useState } from 'react';
import { FlexHorizontal } from 'components/Common';
import { useLocation, useParams } from 'react-router-dom';
import { Content } from 'App';

import PageHeaderStatic from '../../components/PageHeaderStatic';
import { CapabilityType } from 'types';
import PageHeaderCopyBlock from 'components/PageHeaderCopyBlock';
import ItemCarouselByType from 'components/ItemCarouselByType';
import { ApiItemType } from 'components/Item/types';
import Featured from 'components/Featured';
import PageFooter from 'components/PageFooter';
import useWindowSize from 'hooks/useWindowSize';
import colors from 'constants/colors';
import Page from 'components/Page';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';
import { useSelector } from 'react-redux';
import { MainState } from 'state/reducers';
import {
  selectCapabilitiesByIdPreserveOrder,
  selectCoreCapabilityByUrlTitle,
  selectHeadersByIds,
  selectTilesByIds,
  selectTilesByTag,
  selectTradeShowByTagId,
} from 'state/selectors';
import ItemCarousel from 'components/ItemCarousel';
import PageHeader from 'components/PageHeader/hero';

const TradeShowLandingPage: React.FunctionComponent = () => {
  const [page, setPage] = useState(0);
  const [stopAutoRotation, setStopAutoRotation] = useState(false);
  const windowWidth = useWindowSize().width;
  const { tradeShowId } = useParams();
  const { pathname } = useLocation();
  const { isMobile, isTablet } = useIsDevice();

  const coreCapability = useSelector((state: MainState) => selectCoreCapabilityByUrlTitle(state, tradeShowId || ''));
  const tradeShowData = useSelector((state: MainState) => selectTradeShowByTagId(state, coreCapability?.tagId || -1));
  const taggedItems = useSelector((state: MainState) => selectTilesByTag(state, coreCapability?.tagId || -1));
  const featuredItems = useSelector((state: MainState) => selectTilesByIds(state, tradeShowData?.featured || []));
  const relatedCapabililities = useSelector((state: MainState) =>
    selectCapabilitiesByIdPreserveOrder(state, tradeShowData?.capabilityRows || []),
  );
  const headers = useSelector((state: MainState) => selectHeadersByIds(state, tradeShowData?.headers || []));

  if (!tradeShowId) return null;

  const interactiveItems = taggedItems.filter((item) => item.type === ApiItemType.INTERACTIVE);
  const videoItems = taggedItems.filter((item) => item.type === ApiItemType.VIDEO);
  const popupItems = taggedItems.filter((item) => item.type === ApiItemType.POPUP);

  const img = isMobile ? coreCapability?.heroImgMobile || '' : coreCapability?.heroImg || '';
  const imgLarge = coreCapability?.heroImgLarge || '';

  const useLargeImage = windowWidth > 1920;
  const ratio = isMobile ? 450 / mobile.MAX_WIDTH : 700 / 1920;

  const footerLinkText = 'Visit L3Harris.com Event Page';
  const fixedStaticHeaderImg = !!tradeShowData
    ? isMobile
      ? isTablet
        ? tradeShowData.imgTablet
        : tradeShowData.imgMobile
      : tradeShowData.imgLarge
    : '';
  const footerLink = tradeShowData?.footerLink || '';

  const renderCapabilityRows = () => (
    <>
      {relatedCapabililities.map((cap) => {
        const items = taggedItems.filter((item) => item.tags.includes(cap.tagId) && item.type !== ApiItemType.POPUP);
        if (!items.length) return null;
        return (
          <FlexHorizontal className="hasTopMargin" key={cap.tagId}>
            <ItemCarousel items={items} title={cap.label} />
          </FlexHorizontal>
        );
      })}
    </>
  );

  const onPage = (page: number) => {
    setPage(page);
  };

  const onHeaderBlockMouseEnter = () => {
    setStopAutoRotation(true);
  };

  const onHeaderBlockMouseLeave = () => {
    setStopAutoRotation(false);
  };

  const header = headers[page];

  return (
    <Page key={pathname}>
      {fixedStaticHeaderImg && (
        <PageHeaderStatic
          img={fixedStaticHeaderImg}
          imgLarge={fixedStaticHeaderImg}
          noGrad
          minWidth={1700}
          isFixed={!isMobile}
          noPreloader
        />
      )}

      {!!headers.length ? (
        <PageHeader onPage={onPage} items={headers} shouldRotate={!stopAutoRotation} />
      ) : (
        <PageHeaderStatic img={img} imgLarge={imgLarge} useLargeImage={useLargeImage} ratio={ratio} />
      )}

      <Content className="wideOnly">
        <FlexHorizontal className="wideOnly flexend">
          {!!header && (
            <PageHeaderCopyBlock
              title={header?.label || ''}
              copy={header?.text || ''}
              color={colors.red}
              isTradeShow
              onMouseEnter={onHeaderBlockMouseEnter}
              onMouseLeave={onHeaderBlockMouseLeave}
            />
          )}

          {!!featuredItems.length && <Featured items={featuredItems} />}
        </FlexHorizontal>
      </Content>

      <Content style={{ marginBottom: 10 }}>
        {tradeShowData?.displayInteractiveRow && !!interactiveItems.length && (
          <FlexHorizontal className="hasTopMargin">
            <ItemCarouselByType items={interactiveItems} type={ApiItemType.INTERACTIVE} />
          </FlexHorizontal>
        )}

        {tradeShowData?.displayVideoRow && !!videoItems.length && (
          <FlexHorizontal className="hasTopMargin">{<ItemCarouselByType items={videoItems} type={ApiItemType.VIDEO} />}</FlexHorizontal>
        )}

        {renderCapabilityRows()}

        {!!popupItems.length && (
          <FlexHorizontal className="hasTopMargin">{<ItemCarouselByType items={popupItems} type={ApiItemType.POPUP} />}</FlexHorizontal>
        )}
      </Content>

      <PageFooter footerLink={footerLink} footerLinkText={footerLinkText} />
    </Page>
  );
};

export default TradeShowLandingPage;
