export enum CapabilityType {
  // Top level
  DOMAINS = 'domains',
  INDUSTRIES = 'industries',
  SOLUTIONS = 'solutions',
  TRADE_SHOWS = 'trade-shows',
  //DomainType {
  SPACE = 'space',
  AIR = 'air',
  LAND = 'land',
  SEA = 'sea',
  CYBER = 'cyber',
  MULTI_DOMAIN = 'multi-domain',
  // IndustriesType {
  DEFENSE = 'defense',
  COMMERCIAL = 'commercial',
  CIVIL = 'civil',
  // SolutionsType {
  ELECTRONIC_WARFARE = 'electronic-warfare',
  INTEGRATED_MARITIME_SOLUTIONS = 'integrated-maritime-solutions',
  INTEGRATED_VISION_SOLUTIONS = 'integrated-vision-solutions',
  ISR_AND_SIGINT = 'isr-and-sigint',
  MISSILE_WARNING_AND_DEFENSE = 'missile-warning-and-defense',
  PRECISION_MUNITIONS = 'precision-munitions',
  PUBLIC_SAFETY_AND_CRITICAL = 'public-safety-and-critical',
  SOFTWARE_AND_CYBER_SOLUTIONS = 'software-and-cyber-solutions',
  SPACE_SUPERIORITY = 'space-superiority',
  TRAINING_AND_SIMULATION_SYSTEMS = 'training-and-simulation-systems',
  UNDERSEA_WARFARE = 'undersea-warfare',
  AUTONOMOUS_SYSTEMS_SOLUTIONS = 'autonomous-systems-solutions',
  COMMAND_AND_CONTROL_SOLUTIONS = 'command-and-control-solutions',
  AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS = 'aircraft-missionization-and-avionics-solutions',
  RESILIENT_COMMUNICATIONS_AND_NETWORKS_SOLUTIONS = 'resilient-communications-and-networks-solutions',
  // TradeShowsType {
  WORLD_DEFENSE_SHOW_2022 = 'world-defense-show-2022',
  SPACE_SYMPOSIUM = 'space-symposium',
  NAVY_LEAGUE = 'navy-league',
  // TRADE SHOWS
  // world defense show
  WDS_C5ISR_SOLUTIONS = 'wds-c5isr-solutions',
  WDS_COMMERCIAL_AVIATION_SOLUTIONS = 'wds-commercial-aviation-solutions',
  WDS_EOIR_SENSOR_SOLUTIONS = 'wds-eo-ir-sensor-solutions',
  WDS_EW_AND_CYBER_SOLUTIONS = 'wds-ew-and-cyber-solutions',
  WDS_ISR_AND_SIGINT_SOLUTIONS = 'wds-isr-and-sigint-solutions',
  WDS_MARITIME_AND_AUTONOMOUS_SOLUTIONS = 'wds-maritime-and-autonomous-solutions',
  // space symposium
  SS_SPACE_AS_A_WARFIGHTING_DOMAIN = 'ss-space-as-a-warfighting-domain',
  SS_WEATHER = 'ss-weather',
  SS_LAUNCH = 'ss-launch',
  SS_POSITIONING_NAVIGATION_TIMING = 'ss-positioning-navigation-timing',
  // navy league
  NL_ISR_AND_SIGINT_SOLUTIONS = 'nl-isr-and-sigint-solutions', // dupe
  NL_RESILIENT_COMMUNICATIONS_AND_NETWORKS_SOLUTIONS = 'nl-resilient-communications-and-networks-solutions',
  NL_MARITIME_AND_AUTONOMOUS_SYSTEMS = 'nl-maritime-and-autonomous-systems',
  NL_AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS = 'nl-aircraft-missionization-and-avionics-solutions',
  NL_ELECTRONIC_WARFARE_SOLUTIONS = 'nl-electronic-warfare-solutions',
  NL_DISTRIBUTED_MARITIME_OPERATIONS = 'nl-distributed-maritime-operations',
}

//export type CapabilityChildType = DomainType | IndustriesType | SolutionsType | TradeShowsType | TradeShowType;

export enum CapabilityTag {
  AIR_DOMAIN = '1_Air Domain',
  CYBER_DOMAIN = '1_Cyber Domain',
  LAND_DOMAIN = '1_Land Domain',
  MULTI_DOMAIN = '1_Multi-Domain',
  SEA_DOMAIN = '1_Sea Domain',
  SPACE_DOMAIN = '1_Space Domain',
  DEFENSE_INDUSTRY = '2_Defense Industry',
  AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS = '3_Aircraft Missionization and Avionics Solutions',
  AUTONOMOUS_SYSTEMS_SOLUTIONS = '3_Autonomous Systems Solutions',
  COMMAND_AND_CONTROL_SOLUTIONS = '3_Command and Control Solutions',
  ELECTRONIC_WARFARE_SOLUTIONS = '3_Electronic Warfare Solutions',
  INTEGRATED_MARITIME_SOLUTIONS = '3_Integrated Maritime Solutions',
  INTEGRATED_VISION_SOLUTIONS = '3_Integrated Vision Solutions',
  ISR_AND_SIGINT_SOLUTIONS = '3_ISR and SIGINT Solutions',
  MISSILE_WARNING_AND_DEFENSE_SOLUTIONS = '3_Missile Warning and Defense Solutions',
  PRECISION_MUNITIONS_SOLUTIONS = '3_Precision Munitions Solutions',
  RESILIENT_COMMUNICATIONS_AND_NETWORKS_SOLUTIONS = '3_Resilient Communications and Networks Solutions',
  SOFTWARE_AND_CYBER_SOLUTIONS = '3_Software and Cyber Solutions',
  SPACE_SUPERIORITY_SOLUTIONS = '3_Space Superiority Solutions',
  TRAINING_AND_SIMULATION_SYSTEMS_SOLUTIONS = '3_Training and Simulation Systems Solutions',
  UNDERSEA_WARFARE_SOLUTIONS = '3_Undersea Warfare Solutions',
  CIVIL_INDUSTRY = '2_Civil Industry',
  COMMERCIAL_INDUSTRY = '2_Commercial Industry',
  PUBLIC_SAFETY_AND_CRITICAL_COMMUNICATIONS_SOLUTIONS = '3_Public Safety and Critical Communications Solutions',

  //COMMERCIAL_AVIATION_SOLUTIONS = 'Commercial Aviation Solutions',

  NOT_FOUND = '000_NOT_FOUND',

  // World Defense Show Tags
  WDS_EO_IR_SENSOR_SOLUTIONS = 'WDS__EO/IR Sensor Solutions',
  WDS_COMMERCIAL_AVIATION_SOLUTIONS = 'WDS__Commercial Aviation Solutions',
  WDS_C5ISR_SOLUTIONS = 'WDS__C5ISR Solutions',
  WDS_EW_AND_CYBER_SOLUTIONS = 'WDS__EW and Cyber Solutions',
  WDS_MARITIME_AND_AUTONOMOUS_SOLUTIONS = 'WDS__Maritime and Autonomous Solutions',
  WDS_ISR_AND_SIGINT_SOLUTIONS = 'WDS__ISR and SIGINT Solutions',
  WDS_TAG_NEEDED = 'WDS__Tag Needed',

  // Space Symposium Tags
  SS_ANTENNAS = 'SS__Antennas',
  SS_POSITIONING_NAVIGATION_TIMING = 'SS__Positioning, Navigation & Timing',
  SS_MISSLE_WARNING_DEFENSE = 'SS__Missile Warning & Defense',
  SS_SPACE_SUPERIORITY = 'SS__Space Superiority',
  SS_TELESCOPES_EXPLORATION = 'SS__Telescopes & Exploration',
  SS_WEATHER = 'SS__Weather',
  SS_TAG_NEEDED = 'SS__Tag Needed',

  // Navy League Tags
  NL_MARITIME_AND_AUTONOMOUS_SYSTEMS = 'NL__Maritime and Autonomous Systems',
  NL_RESILIENT_COMMUNICATIONS_AND_NETWORKS = 'NL__Resilient Communications and Networks',
  NL_ELECTRONIC_WARFARE_SOLUTIONS = 'NL__Electronic Warfare Solutions',
  NL_DISTRIBUTED_MARITIME_OPERATIONS = 'NL__Distributed Maritime Operations',
  NL_ISR_AND_SIGINT_SOLUTIONS = 'NL__ISR and SIGINT Solutions',
  NL_AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS = 'NL__Aircraft Missionization and Avionics Solutions',
  NL_TAG_NEEDED = 'NL__Tag Needed',
}

export type ApiLayouts = {
  home: {
    id: number;
    latest: number[];
    interactive: number[];
    video: number[];
    headers: number[];
  };
};

export type ApiHeader = {
  id: number;
  label: string;
  text: string;
  type: ApiHeaderType;
  url: string;
  tile: number;
  img: string;
  imgLarge: string;
  imgMobile: string;
};

export enum ApiHeaderType {
  NONE = 'none',
  TILE = 'tile',
  URL = 'url',
}

export type ApiTradeShow = {
  id: number;
  label: string;
  img: string;
  imgLarge: string;
  imgMobile: string;
  imgTablet: string;
  tagId: number;
  footerLink: string;
  displayCapabilities?: boolean;
  featured: number[];
  displayInteractiveRow?: boolean;
  displayVideoRow?: boolean;
  capabilityRows: number[];
  headers: number[];
};
