import React from 'react';
import { FlexHorizontal } from 'components/Common';
import { useLocation, useParams } from 'react-router-dom';
import { Content } from 'App';
import { sortBy } from 'lodash';

import PageHeaderStatic from '../../components/PageHeaderStatic';
import { CapabilityType } from 'types';
import PageHeaderCopyBlock from 'components/PageHeaderCopyBlock';
import ItemCarouselByType from 'components/ItemCarouselByType';
import { ApiItemType } from 'components/Item/types';
import Featured from 'components/Featured';
import PageFooter from 'components/PageFooter';
import useWindowSize from 'hooks/useWindowSize';
import Page from 'components/Page';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';
import { useSelector } from 'react-redux';
import { MainState } from 'state/reducers';
import { selectCoreCapabilityByUrlTitle, selectTilesByIds, selectTilesByTag } from 'state/selectors';

const CapabilityChildPage: React.FunctionComponent = () => {
  const windowWidth = useWindowSize().width;
  const { capabilityChildId, capabilityId } = useParams();
  const { pathname } = useLocation();
  const { isMobile } = useIsDevice();

  const coreCapability = useSelector((state: MainState) => selectCoreCapabilityByUrlTitle(state, capabilityChildId || ''));

  const taggedItems = useSelector((state: MainState) => selectTilesByTag(state, coreCapability?.tagId || -1));
  const featuredItems = useSelector((state: MainState) => selectTilesByIds(state, coreCapability?.featured || []));

  if (!capabilityChildId) return null;

  const interactiveItems = taggedItems.filter((item) => item.type === ApiItemType.INTERACTIVE);
  const videoItems = taggedItems.filter((item) => item.type === ApiItemType.VIDEO);
  const popupItems = taggedItems.filter((item) => item.type === ApiItemType.POPUP);

  const footer = { link: coreCapability?.footerUrl }; //taggedItems.find((item) => item.type === ItemType.FOOTER_LINK);

  const img = isMobile ? coreCapability?.heroImgMobile : coreCapability?.heroImg;
  const imgLarge = coreCapability?.heroImgLarge;

  const useLargeImage = windowWidth > 1920;
  const ratio = isMobile ? 450 / mobile.MAX_WIDTH : 700 / 1920;

  return (
    <Page key={pathname}>
      {img && imgLarge && <PageHeaderStatic img={img} imgLarge={imgLarge} useLargeImage={useLargeImage} ratio={ratio} />}

      <Content className="wideOnly">
        <FlexHorizontal className="wideOnly flexend">
          <PageHeaderCopyBlock
            title={coreCapability?.label || ''}
            copy={coreCapability?.heroCopy || ''}
            label={capabilityId || ''}
            subtitle={coreCapability?.heroSubTitle || ''}
          />
          {!!featuredItems.length && <Featured items={featuredItems} />}
        </FlexHorizontal>
      </Content>
      <Content style={{ marginBottom: 10 }}>
        {!!interactiveItems.length && (
          <FlexHorizontal className="hasTopMargin">
            <ItemCarouselByType items={interactiveItems} type={ApiItemType.INTERACTIVE} />
          </FlexHorizontal>
        )}

        {!!videoItems.length && (
          <FlexHorizontal className="hasTopMargin">
            <ItemCarouselByType items={videoItems} type={ApiItemType.VIDEO} />
          </FlexHorizontal>
        )}

        {!!popupItems.length && (
          <FlexHorizontal className="hasTopMargin">
            <ItemCarouselByType items={popupItems} type={ApiItemType.POPUP} />
          </FlexHorizontal>
        )}
      </Content>
      <PageFooter
        footerLink={!!footer.link ? footer.link : undefined}
        footerLinkText={!!footer.link ? 'Learn more on L3Harris.com' : undefined}
      />
    </Page>
  );
};

export default CapabilityChildPage;
