import { useEffect, useLayoutEffect, useState } from 'react';
import useEventListener from 'hooks/useEventListener';
import useWindowSize from 'hooks/useWindowSize';
import mobile from 'constants/mobile';
import tablet from 'constants/tablet';
import { useMediaQuery } from 'react-responsive';

function useIsDevice() {
  const isMobile = useMediaQuery({ maxWidth: mobile.MAX_WIDTH });
  const isTablet = useMediaQuery({ minWidth: tablet.MIN_WIDTH, maxWidth: tablet.MAX_WIDTH });

  // const [isMobile, setIsMobile] = useState(false);
  // const [isTablet, setIsTablet] = useState(false);

  // const windowSize = useWindowSize();

  // useEffect(() => {
  //   const isMobile = windowSize.width === 0 ? false : windowSize.width <= mobile.MAX_WIDTH;
  //   const isTablet = windowSize.width === 0 ? false : windowSize.width <= tablet.MAX_WIDTH && windowSize.width >= tablet.MIN_WIDTH;
  //   setIsMobile(isMobile);
  //   setIsTablet(isTablet);

  //   console.log('recalculating ' + windowSize.width + ' isMobile: ' + isMobile + ' isTablet: ' + isTablet);
  // }, [windowSize]);

  return {
    isMobile,
    isTablet,
  };
}

export default useIsDevice;
