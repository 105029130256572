import { ItemTypeImages } from 'constants/index';
import colors from 'constants/colors';
import { getItemSize, calculateItemSize, getItemImage, getAdditionalImage } from 'helpers';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ApiItem, ApiItemType, Item as ItemProps, ItemSize, ItemType } from './types';

import ItemExpanded from 'components/ItemExpanded';
import { typeIconSize } from 'components/SubHeadingIcon';
import ImageLoader from 'components/ImageLoader';
import classNames from 'classnames';
import mobile from 'constants/mobile';

type Props = ApiItem & {
  width: number;
  minWidth?: boolean;
  maxWidth?: boolean;
  hideIcons?: boolean;
  marginRight?: number;
  marginBottom?: number;
  noPreloader?: boolean;
  isMobile?: boolean;
  size: ItemSize;
};

const trackingMap = {
  [ApiItemType.VIDEO]: 'openvideo',
  [ApiItemType.INTERACTIVE]: 'openceros',
  [ApiItemType.POPUP]: 'openpopup',
  //[ApiItemType.FOOTER_LINK]: 'openfooter', // not poss
};

const Item: React.FunctionComponent<Props> = (props) => {
  const [size, setSize] = useState(calculateItemSize(props.size, props.width, props.minWidth, props.maxWidth));
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState<DOMRect>();

  const selfRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSize(calculateItemSize(props.size, props.width, props.minWidth, props.maxWidth));
  }, [props.width]);

  const onClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: trackingMap[props.type], content_id: props.id, content_name: props.label });

    if (selfRef.current) {
      const rect = selfRef.current.getBoundingClientRect();
      setPosition(rect);
    }

    setIsOpen(true);
  };

  const onClose = (e: React.MouseEvent<Element, MouseEvent>) => {
    const target = e.target as HTMLElement;
    if (!!target && !target.classList.contains('close-item')) {
      return;
    }
    setIsOpen(false);
  };

  // const image =
  //   props.type === ItemType.POPUP ? (size.width > 200 ? popupImgLarge : popupImgSmall) : size.width > 450 ? itemImgLarge : itemImgSmall;

  const image =
    props.type === ApiItemType.POPUP
      ? size.width > 200
        ? props.imgLarge //getAdditionalImage(props.id, true)
        : props.img //getAdditionalImage(props.id, false)
      : size.width > 450
      ? props.imgLarge //getItemImage(props.id, true)
      : props.img; //getItemImage(props.id, false);

  const containerStyles = {
    width: size.width,
    minWidth: size.width,
    minHeight: size.height,
    marginRight: props.marginRight !== undefined ? props.marginRight : 3,
    marginBottom: props.marginBottom !== undefined ? props.marginBottom : 0,
  };
  return (
    <>
      <Container
        height={size.height}
        onClick={onClick}
        ref={selfRef}
        style={containerStyles}
        className={classNames('container', props.type)}
      >
        <ItemImage src={image} />

        <Grad className="grad" />
        <TextWrap>
          {!props.hideIcons && (
            <div className="iconWrap">
              {/* <Icon src={ItemTypeImages[props.type]} width={typeIconSize[props.type].width} height={typeIconSize[props.type].height} /> */}
              <Icon src={ItemTypeImages[props.type]} width="100%" />
            </div>
          )}
          <Text className="label">{props.label}</Text>
        </TextWrap>
      </Container>
      {isOpen && position && (
        <ItemExpanded item={props} onClose={onClose} startingPosition={position} hideIcons={props.hideIcons} itemImg={image} />
      )}
    </>
  );
};

export const ItemImage = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  .textUnder & {
    position: relative;
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    position: relative;
    &.expanded {
      position: absolute;
    }
  }
`;
const Container = styled.div<{ height: number }>`
  background-color: ${colors.black};

  border-radius: 3px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  white-space: normal;

  &:hover .grad {
    height: 4.5vw;
  }

  .textUnder & {
    flex-direction: column;
    justify-content: flex-start;
    background-color: transparent;
    ${({ height }) => `
    min-height: ${height + 50}px;
    `};
    .grad {
      display: none;
    }
    .label {
      margin-top: 10px;
      margin-left: 0;
      padding: 0;
    }
  }

  @media (max-width: ${mobile.MAX_WIDTH}px) {
    flex-direction: column;
    justify-content: flex-start;
    background-color: transparent;
    ${({ height }) => `
    min-height: ${height + 50}px;
    `};
    .grad {
      display: none;
    }
    .label {
      margin-top: 10px;
      margin-left: 0;
      padding: 0;
    }
  }
`;

export const TextWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px 6px 10px;
  text-align: left;
  width: 100%;
  color: ${colors.white};

  z-index: 1;
  .textUnder & {
    padding: 0 4px 0 4px;
  }
  .iconWrap {
    width: 5%;
    margin-right: 2px;
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      width: 3vw;
      max-width: 16px;
      margin-right: 10px;
      margin-top: 7px;
      img {
        display: block;
        margin-right: 10px;
      }
    }
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    padding: 0 4px 0 4px;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  font-size: 0.9vw;
  font-weight: 400;
  margin-left: 6px;
  flex: 1;
  .popup & {
    .searchbar & {
      //font-size: 13px;
      font-size: 0.7vw;
      font-size: clamp(13px, 0.7vw, 19px);
      @media (max-width: ${mobile.MAX_WIDTH}px) {
        font-size: 2.8vw;
        font-size: clamp(11px, 2.8vw, 15px);
      }
    }
  }
  .searchbar & {
    //font-size: 16px;
    font-size: 0.8vw;
    font-size: clamp(14px, 0.8vw, 19px);
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    font-weight: 600;
    font-size: 2.8vw;
    font-size: clamp(11px, 2.8vw, 15px);
  }
  .menu & {
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      font-size: 2.6vw;
      font-size: clamp(11px, 2.6vw, 15px);
    }
  }
`;

export const Icon = styled.img`
  margin-right: 5px;
  margin-right: 0.28vw;
  max-width: 16px;
  min-width: 13px;
  // @media (max-width: ${mobile.MAX_WIDTH}px) {
  //   display: none;
  // }
`;

export const Grad = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  height: 3.1vw;
  background: ${colors.faded.black};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  transition: height 0.3s ease-in-out;
`;

export default Item;
