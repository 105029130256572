export default {
  background: '#191c1c',
  red: '#ff000a', //'#ea3327',
  black: '#000000',
  white: '#ffffff',
  darkGrey: '#283030',
  blueBox: '#172430',
  navGrey: '#737373',
  paginationGrey: '#333333',
  faded: {
    darkGrey: 'rgba(40, 48, 48, 0.6)',
    black: 'rgba(0, 0, 0, 0.6)',
  },
  fadedEnabled: {
    darkGrey: 'rgba(40, 48, 48, 0.9)',
    black: 'rgba(0, 0, 0, 0.9)',
  },
  customFade: {
    darkGreyGreen: (amount: number) => `rgba(40, 48, 48, ${amount})`,
    darkGrey: (amount: number) => `rgba(25, 28, 28, ${amount})`,
  },
};
