import React from 'react';
import { FlexHorizontal } from 'components/Common';
import { useLocation, useParams } from 'react-router-dom';
import { Content } from 'App';

import PageHeaderStatic from '../../components/PageHeaderStatic';
import { CapabilityType } from 'types';
import PageHeaderCopyBlock from 'components/PageHeaderCopyBlock';
import ItemCarouselByType from 'components/ItemCarouselByType';
import { ApiItemType } from 'components/Item/types';
import Featured from 'components/Featured';
import PageFooter from 'components/PageFooter';
import useWindowSize from 'hooks/useWindowSize';
import colors from 'constants/colors';
import Page from 'components/Page';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';
import { useSelector } from 'react-redux';
import { MainState } from 'state/reducers';
import { selectCoreCapabilityByUrlTitle, selectTilesByIds, selectTilesByTag, selectTradeShowByTagId } from 'state/selectors';

const TradeShowCapabilityPage: React.FunctionComponent = () => {
  const windowWidth = useWindowSize().width;
  const { tradeShowId, tradeShowCapabilityId } = useParams();
  const { pathname } = useLocation();
  const { isMobile, isTablet } = useIsDevice();

  const coreCapability = useSelector((state: MainState) => selectCoreCapabilityByUrlTitle(state, tradeShowCapabilityId || ''));
  const taggedItems = useSelector((state: MainState) => selectTilesByTag(state, coreCapability?.tagId || -1));
  const featuredItems = useSelector((state: MainState) => selectTilesByIds(state, coreCapability?.featured || []));
  const tradeShowData = useSelector((state: MainState) => selectTradeShowByTagId(state, coreCapability?.tradeShowTagId || -1));

  if (!tradeShowId || !tradeShowCapabilityId) return null;

  const capabilityId = tradeShowCapabilityId as CapabilityType;

  const interactiveItems = taggedItems.filter((item) => item.type === ApiItemType.INTERACTIVE);
  const videoItems = taggedItems.filter((item) => item.type === ApiItemType.VIDEO);
  const popupItems = taggedItems.filter((item) => item.type === ApiItemType.POPUP);

  const img = isMobile ? coreCapability?.heroImgMobile || '' : coreCapability?.heroImg || '';
  const imgLarge = coreCapability?.heroImgLarge || '';

  const useLargeImage = windowWidth > 1920;
  const ratio = isMobile ? 450 / mobile.MAX_WIDTH : 700 / 1920;

  const footerLinkText = 'Visit L3Harris.com Event Page';
  const fixedStaticHeaderImg = !!tradeShowData
    ? isMobile
      ? isTablet
        ? tradeShowData.imgTablet
        : tradeShowData.imgMobile
      : tradeShowData.imgLarge
    : '';
  const footerLink = tradeShowData?.footerLink || '';

  return (
    <Page key={pathname}>
      {fixedStaticHeaderImg && (
        <PageHeaderStatic
          img={fixedStaticHeaderImg}
          imgLarge={fixedStaticHeaderImg}
          noGrad
          minWidth={1700}
          isFixed={!isMobile}
          noPreloader
        />
      )}

      <PageHeaderStatic img={img} imgLarge={imgLarge} useLargeImage={useLargeImage} ratio={ratio} />

      <Content className="wideOnly">
        <FlexHorizontal className="wideOnly flexend">
          <PageHeaderCopyBlock
            title={coreCapability?.label || ''}
            copy={coreCapability?.heroCopy || ''}
            label={capabilityId || ''}
            subtitle={coreCapability?.heroSubTitle || ''}
            color={colors.red}
            isTradeShow
          />
          {!!featuredItems.length && <Featured items={featuredItems} />}
        </FlexHorizontal>
      </Content>
      <Content style={{ marginBottom: 10 }}>
        {!!interactiveItems.length && (
          <FlexHorizontal className="hasTopMargin">
            <ItemCarouselByType items={interactiveItems} type={ApiItemType.INTERACTIVE} />
          </FlexHorizontal>
        )}

        {!!videoItems.length && (
          <FlexHorizontal className="hasTopMargin">{<ItemCarouselByType items={videoItems} type={ApiItemType.VIDEO} />}</FlexHorizontal>
        )}

        {!!popupItems.length && (
          <FlexHorizontal className="hasTopMargin">{<ItemCarouselByType items={popupItems} type={ApiItemType.POPUP} />}</FlexHorizontal>
        )}
      </Content>

      <PageFooter footerLink={footerLink} footerLinkText={footerLinkText} />
    </Page>
  );
};

export default TradeShowCapabilityPage;
