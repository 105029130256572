import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import colors from 'constants/colors';
import { Heading, Paragraph, ScaledHeading, ScaledSecondaryLabel, ScaledParagraph, ScaledHeadingUnderlined } from 'components/Type';
import ChevronButton from '../ChevronButton';
import { useNavigate } from 'react-router-dom';
import { ApiItem, Item } from 'components/Item/types';
import ItemExpanded from 'components/ItemExpanded';
import useWindowSize from 'hooks/useWindowSize';
import { scaleBasedOnDesign } from 'helpers/index';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';
import { useSelector } from 'react-redux';
import { MainState } from 'state/reducers';
import { selectTileById } from 'state/selectors';

type Props = {
  title: string;
  copy: string;
  color?: string;
  label?: string;
  subtitle?: string;
  link?: string;
  isTradeShow?: boolean;
  item?: number; //ApiItem;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const designWindowSize = 1920;
const designBoxWidth = 332;
const designBoxHeight = 388;
const designBasedRatio = designBoxWidth / designWindowSize;

const PageHeaderCopyBlock: React.FunctionComponent<Props> = (props) => {
  const navigation = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState<DOMRect>();
  const selfRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowSize().width;

  const item = useSelector((state: MainState) => selectTileById(state, props.item || 0));

  const { isMobile } = useIsDevice();
  const width = isMobile ? 100 : Math.round(windowWidth * designBasedRatio);
  const height = isMobile ? 100 : Math.round(width * (designBoxHeight / designBoxWidth));

  const chevronTextSize = scaleBasedOnDesign({ originalValue: 16, minValue: 14, newWidth: windowWidth });

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpenItem = () => {
    if (selfRef.current) {
      const rect = selfRef.current.getBoundingClientRect();
      setPosition(rect);
    }

    setIsOpen(true);
  };

  if (props.isTradeShow) {
    return (
      <Container
        color={props.color}
        ref={selfRef}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        width={width}
        height={height}
      >
        <ScaledHeading content={props.title} noBottomMargin />
        {/* {!!props.copy && <ScaledParagraph content={props.copy} />} */}
        {!!props.link && (
          <ChevronButton onClick={() => !!props.link && navigation(props.link)} label="Explore" fontSize={chevronTextSize} />
        )}
      </Container>
    );
  }

  return props.label ? (
    <Container
      color={props.color}
      ref={selfRef}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      width={width}
      height={height}
    >
      <ScaledSecondaryLabel content={props.label} />
      <ScaledHeadingUnderlined content={props.title} />

      {props.subtitle && <ScaledParagraph content={`<strong>${props.subtitle}</strong>`} />}
      {props.label.toLowerCase() !== 'solution' && <ScaledParagraph content={props.copy} />}
    </Container>
  ) : (
    <Container color={props.color} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} width={width} height={height}>
      <ScaledHeading content={props.title} />

      <ScaledParagraph noMobile content={props.copy} />
      {!!props.link && <ChevronButton onClick={() => !!props.link && navigation(props.link)} label="Explore" fontSize={chevronTextSize} />}
      {!!props.item && (
        <div ref={selfRef}>
          <ChevronButton onClick={onOpenItem} label="Explore" fontSize={chevronTextSize} />
        </div>
      )}
      {!!item && isOpen && position && (
        <ItemExpanded hideContentExpand item={item} onClose={onClose} startingPosition={position} hideIcons />
      )}
    </Container>
  );
};

export default PageHeaderCopyBlock;

const Container = styled.div<{ color?: string; width: number; height: number }>`
  ${({ width, height }) => `
    min-width: ${designBoxWidth}px;
    max-width: ${width}px;
    min-height: ${height}px;
  `}
  background-color: ${({ color }) => (!!color ? color : colors.blueBox)};
  margin-top: -144px;
  padding: 34px 20px;
  border-radius: 3px;
  z-index: 2;

  @media (max-width: ${mobile.MAX_WIDTH}px) {
    min-width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 0;
    margin-top: 0;
    display: block;
    border-radius: 0;
  }
`;

// CAN I DELETE THIS LABEL ?!??!
export const Label = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: 'IBM Plex Sans', sans-serif;
  display: block;
`;

export const HeadingVariant = styled.h3`
  font-size: 30px;
  line-height: 45px;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 12px;
  span {
    border-bottom: 4px solid ${colors.red};
    border-bottom-width: 0.25vw;
    border-bottom-width: clamp(2px, 0.25vw, 6px);

    margin-bottom: 4px;
    margin-bottom: 0.25vw;
    margin-bottom: clamp(2px, 0.25vw, 6px);
  }
`;
