import Item from 'components/Item';
import { ApiItem, ApiItemType, Item as ItemProps, ItemSize, ItemType } from 'components/Item/types';
import Modal from 'components/Modal';
import SubHeadingIcon from 'components/SubHeadingIcon';
import { ScaledSubHeading, SubHeading } from 'components/Type';
import colors from 'constants/colors';
import mobile from 'constants/mobile';
import { getItemSize } from 'helpers';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type Props = {
  items: ApiItem[];
  title: string;
  type?: ApiItemType;
  hideIcons?: boolean;
};

const ViewAllButton: React.FunctionComponent<Props> = (props) => {
  const [viewing, setViewing] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const calculateWidth = () => {
    const width = contentRef.current?.getBoundingClientRect().width;
    setContentWidth(Math.max((width || 0) - 10, 0));
  };

  useEffect(() => {
    if (contentRef.current) {
      const to = setTimeout(calculateWidth, 600);
      return () => clearTimeout(to);
    }
  }, [contentRef.current, viewing]);

  useEffect(() => {
    if (viewing) {
      const to = setTimeout(() => setFadeIn(true), 600);
      return () => clearTimeout(to);
    } else {
      setFadeIn(false);
    }
  }, [viewing]);

  useLayoutEffect(() => {
    window.addEventListener('resize', calculateWidth);
    return () => window.removeEventListener('resize', calculateWidth);
  }, []);

  const onClick = () => {
    setViewing(!viewing);
  };
  const onClose = (e: React.MouseEvent<Element, MouseEvent>) => {
    const target = e.target as HTMLElement;
    if (!!target && !target.classList.contains('close-viewall')) {
      return;
    }
    setViewing(false);
  };

  const numPerRow = props.type === ApiItemType.POPUP ? 8 : 5;
  const itemWidth = (contentWidth - numPerRow * 3) / numPerRow;
  const RATIO = props.type === ApiItemType.POPUP ? 1 : 9 / 16;
  const itemHeight = itemWidth * RATIO;

  return (
    <>
      <Container onClick={onClick}>&gt; View all</Container>
      {viewing && (
        <Modal onClose={onClose} closeWidth={57} backgroundOpacity={0.95}>
          <Content ref={contentRef} isActive={fadeIn} className="close-viewall">
            <div>
              <ScaledSubHeading>
                {props.title}
                {!!props.type && <SubHeadingIcon type={props.type} />}
              </ScaledSubHeading>
              <Items itemWidth={itemWidth} itemHeight={itemHeight}>
                {props.items.map((item, idx) => (
                  <Item {...item} width={itemWidth} key={`item-${idx}`} hideIcons={props.hideIcons} noPreloader size={ItemSize.SMALL} />
                ))}
              </Items>
            </div>
          </Content>
        </Modal>
      )}
    </>
  );
};

const Container = styled.div<{ flip?: boolean }>`
  font-size: 14px;
  font-size: 0.73vw;
  font-size: clamp(11px, 0.73vw, 15vw);
  font-weight: 600;
  color: #737373;
  text-transform: uppercase;
  margin-top: 7px;
  display: inline-block;
  float: right;
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  &:hover {
    color: #939393;
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: none;
  }
`;

const Content = styled.div<{ isActive?: boolean }>`
  flex: 1;
  height: 94vh;
  display: flex;
  align-items: center;
  opacity: ${({ isActive }) => (!!isActive ? 1 : 0)};
  transition: opacity 0.35s ease-in-out;
  > div {
    flex: 1;
  }
`;

const Items = styled.div<{ itemWidth: number; itemHeight: number }>`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 90vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, ${({ itemWidth }) => itemWidth}px);
  grid-auto-rows: ${({ itemHeight }) => itemHeight}px;
  row-gap: 20px;
  column-gap: 3px;
  padding: 10px 0;
`;

export default ViewAllButton;
