import { domains, industries, solutions, tradeShows } from 'constants/capabilities';
import { CapabilityTag, CapabilityType } from 'types';
import { ItemSize } from '../components/Item/types';

export const getItemSize = (size: ItemSize) => {
  switch (size) {
    case ItemSize.SMALL:
      return { width: 340, height: 191 };
    case ItemSize.MEDIUM:
      return { width: 680, height: 385 };
    case ItemSize.LARGE:
      return { width: 426, height: 640 };
  }
  return { width: 340, height: 191 };
};

export const getCapabilitySize = () => ({
  height: 640,
  width: 426,
});

const RATIO = 9 / 16;

export const calculateItemSize = (size: ItemSize, forcedWidthFractional: number, minWidth = false, maxWidth = false) => {
  const forcedWidth = Math.round(forcedWidthFractional);
  switch (size) {
    case ItemSize.SQUARE:
      return { width: forcedWidth, height: forcedWidth };
    case ItemSize.SMALL:
      const newWidth = minWidth && forcedWidth < 340 ? 340 : maxWidth && forcedWidth > 340 ? 340 : forcedWidth;
      return { width: newWidth, height: Math.round(newWidth * RATIO) };
    case ItemSize.MEDIUM:
      return { width: 0 + forcedWidth * 2, height: (3 + forcedWidth) * 2 * RATIO };
    case ItemSize.LARGE:
      return { width: 10, height: 10 };
  }
};

// export const getCapabilityTypeByChildType = (type: CapabilityChildType) => {
//   if (Object.values(DomainType).includes(type as DomainType)) return CapabilityType.DOMAINS;
//   if (Object.values(IndustriesType).includes(type as IndustriesType)) return CapabilityType.INDUSTRIES;
//   if (Object.values(SolutionsType).includes(type as SolutionsType)) return CapabilityType.SOLUTIONS;
//   if (Object.values(TradeShowsType).includes(type as TradeShowsType)) return CapabilityType.TRADE_SHOWS;
// };

export const getCapabilityChildren = (capability: CapabilityType) => {
  switch (capability) {
    case CapabilityType.DOMAINS:
      return domains;
    case CapabilityType.INDUSTRIES:
      return industries;
    case CapabilityType.SOLUTIONS:
      return solutions;
    case CapabilityType.TRADE_SHOWS:
      return tradeShows;
  }
};

export const getCapabilityBaseUrl = (type: CapabilityType) => `/capabilities/${type}`;
export const getCapabilityUrl = (type: CapabilityType, childType: CapabilityType) => `${getCapabilityBaseUrl(type)}/${childType}`;

export const getTradeShowBaseUrl = (type: CapabilityType) => `/trade-show/${type}`;
export const getTradeShowCapabilityUrl = (type: CapabilityType, childType: CapabilityType) => `${getTradeShowBaseUrl(type)}/${childType}`;

export const getItemImage = (id: string, large?: boolean) => `/images/assets/${id}_Mosaic_Tile_${!!large ? 'L' : 'S'}.jpg`;
export const getAdditionalImage = (id: string, large?: boolean) =>
  `/images/assets/${id}_Additional_Capabilities_${!!large ? 'L' : 'S'}.jpg`;
export const getAdditionalHero = (id: string, large?: boolean) =>
  `/images/assets/${id}_Additional_Capabilities_Hero_${!!large ? 'L' : 'S'}.jpg`;
export const getAdditionalHeroMobile = (id: string) => `/images/assets/${id}_Additional_Capabilities_Hero_M.jpg`;

export const getCapabilityImage = (id: number, large?: boolean) => `/images/assets/${id}_Capabilities_${!!large ? 'L' : 'S'}.jpg`;

export const getCapabilityTag = (childType: CapabilityType) => {
  switch (childType) {
    case CapabilityType.AIR:
      return CapabilityTag.AIR_DOMAIN;
    case CapabilityType.CYBER:
      return CapabilityTag.CYBER_DOMAIN;
    case CapabilityType.LAND:
      return CapabilityTag.LAND_DOMAIN;
    case CapabilityType.MULTI_DOMAIN:
      return CapabilityTag.MULTI_DOMAIN;
    case CapabilityType.SEA:
      return CapabilityTag.SEA_DOMAIN;
    case CapabilityType.SPACE:
      return CapabilityTag.SPACE_DOMAIN;

    case CapabilityType.CIVIL:
      return CapabilityTag.CIVIL_INDUSTRY;
    case CapabilityType.COMMERCIAL:
      return CapabilityTag.COMMERCIAL_INDUSTRY;
    case CapabilityType.DEFENSE:
      return CapabilityTag.DEFENSE_INDUSTRY;

    case CapabilityType.RESILIENT_COMMUNICATIONS_AND_NETWORKS_SOLUTIONS:
      return CapabilityTag.RESILIENT_COMMUNICATIONS_AND_NETWORKS_SOLUTIONS;
    case CapabilityType.ELECTRONIC_WARFARE:
      return CapabilityTag.ELECTRONIC_WARFARE_SOLUTIONS;
    case CapabilityType.INTEGRATED_MARITIME_SOLUTIONS:
      return CapabilityTag.INTEGRATED_MARITIME_SOLUTIONS;
    case CapabilityType.INTEGRATED_VISION_SOLUTIONS:
      return CapabilityTag.INTEGRATED_VISION_SOLUTIONS;
    case CapabilityType.ISR_AND_SIGINT:
      return CapabilityTag.ISR_AND_SIGINT_SOLUTIONS;
    case CapabilityType.MISSILE_WARNING_AND_DEFENSE:
      return CapabilityTag.MISSILE_WARNING_AND_DEFENSE_SOLUTIONS;
    case CapabilityType.PRECISION_MUNITIONS:
      return CapabilityTag.PRECISION_MUNITIONS_SOLUTIONS;
    case CapabilityType.PUBLIC_SAFETY_AND_CRITICAL:
      return CapabilityTag.PUBLIC_SAFETY_AND_CRITICAL_COMMUNICATIONS_SOLUTIONS;
    case CapabilityType.SOFTWARE_AND_CYBER_SOLUTIONS:
      return CapabilityTag.SOFTWARE_AND_CYBER_SOLUTIONS;
    case CapabilityType.AUTONOMOUS_SYSTEMS_SOLUTIONS:
      return CapabilityTag.AUTONOMOUS_SYSTEMS_SOLUTIONS;
    case CapabilityType.COMMAND_AND_CONTROL_SOLUTIONS:
      return CapabilityTag.COMMAND_AND_CONTROL_SOLUTIONS;
    case CapabilityType.SPACE_SUPERIORITY:
      return CapabilityTag.SPACE_SUPERIORITY_SOLUTIONS;
    case CapabilityType.TRAINING_AND_SIMULATION_SYSTEMS:
      return CapabilityTag.TRAINING_AND_SIMULATION_SYSTEMS_SOLUTIONS;
    case CapabilityType.UNDERSEA_WARFARE:
      return CapabilityTag.UNDERSEA_WARFARE_SOLUTIONS;
    case CapabilityType.AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS:
      return CapabilityTag.AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS;

    // case TradeShowsType.CIVIL:
    //   return CapabilityTag.civ;
    // case TradeShowsType.COMMERCIAL:
    //   return CapabilityTag.COMMERCIAL_AVIATION_SOLUTIONS;
    // case TradeShowsType.WORLD_DEFENSE_SHOW_2022:
    //     return CapabilityTag.

    case CapabilityType.WDS_C5ISR_SOLUTIONS:
      return CapabilityTag.WDS_C5ISR_SOLUTIONS;
    case CapabilityType.WDS_COMMERCIAL_AVIATION_SOLUTIONS:
      return CapabilityTag.WDS_COMMERCIAL_AVIATION_SOLUTIONS;
    case CapabilityType.WDS_EOIR_SENSOR_SOLUTIONS:
      return CapabilityTag.WDS_EO_IR_SENSOR_SOLUTIONS;
    case CapabilityType.WDS_EW_AND_CYBER_SOLUTIONS:
      return CapabilityTag.WDS_EW_AND_CYBER_SOLUTIONS;
    case CapabilityType.WDS_ISR_AND_SIGINT_SOLUTIONS:
      return CapabilityTag.WDS_ISR_AND_SIGINT_SOLUTIONS;
    case CapabilityType.WDS_MARITIME_AND_AUTONOMOUS_SOLUTIONS:
      return CapabilityTag.WDS_MARITIME_AND_AUTONOMOUS_SOLUTIONS;
  }
};

export const randColor = () => ['red', 'orange', 'yellow', 'green', 'blue', 'purple'][(Math.random() * 6) | 0]; //'#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);

type ScaleBasedOnDesign = {
  originalValue: number;
  newWidth: number;
  minValue?: number;
};

export const scaleBasedOnDesign = ({ originalValue, newWidth, minValue }: ScaleBasedOnDesign) => {
  const designWindowSize = 1920;
  const ratio = newWidth / designWindowSize;
  const newValue = originalValue * ratio;
  return Math.round(minValue ? Math.max(newValue, minValue) : newValue);
};

export const copyToClipboard = (str: string) => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) return navigator.clipboard.writeText(str);
  return Promise.reject('The Clipboard API is not available.');
};
