import classNames from 'classnames';
import HeaderGradient from 'components/HeaderGradient';
import ImageLoader from 'components/ImageLoader';
import mobile from 'constants/mobile';
import useWindowSize from 'hooks/useWindowSize';
import React from 'react';
import styled from 'styled-components';

type Props = {
  img: string;
  imgLarge: string;
  noGrad?: boolean;
  minWidth?: number;
  useLargeImage?: boolean;
  isFixed?: boolean;
  ratio?: number;
  noPreloader?: boolean;
  noMobile?: boolean;
  noShadow?: boolean;
};

const PageHeaderStatic: React.FunctionComponent<Props> = (props) => {
  const { img, imgLarge, noGrad, minWidth, useLargeImage, isFixed, ratio, noPreloader, noMobile, noShadow } = props;
  const windowWidth = useWindowSize().width - 6;
  const src = useLargeImage ? imgLarge : img;
  return (
    <Container
      className={classNames('container', { noShadow: noShadow })}
      isFixed={isFixed}
      height={!!ratio ? Math.round(windowWidth * ratio) : undefined}
      noMobile={noMobile}
    >
      {!noGrad && <HeaderGradient />}
      <ImageContainer className="imageContainer" minWidth={minWidth} height={!!ratio ? Math.round(windowWidth * ratio) : undefined}>
        {!!noPreloader ? (
          <HeaderImage src={src} minWidth={minWidth} />
        ) : (
          <ImageLoader src={src} style={{ position: 'relative' }} duration={1}>
            <HeaderImage src={src} minWidth={minWidth} />
          </ImageLoader>
        )}
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div<{ isFixed?: boolean; height?: number; noMobile?: boolean }>`
  width: 100%;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.3);
  &.noShadow {
    box-shadow: none;
  }
  ${({ height }) =>
    !!height &&
    `
    height: ${height}px;
  `}
  ${({ isFixed }) =>
    !!isFixed &&
    `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
  `}
  ${({ noMobile }) =>
    !!noMobile &&
    `
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: none;
  }
  `}
`;

const ImageContainer = styled.div<{ minWidth?: number; height?: number }>`
  width: 100%;
  min-width: ${({ minWidth }) => (!!minWidth ? minWidth : 700)}px;
  ${({ height }) =>
    !!height &&
    `
    height: ${height}px;
  `}
  display: block;
  margin-left: 50%;
  transform: translateX(-50%);
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    min-width: 0;
  }
`;
const HeaderImage = styled.img<{ minWidth?: number }>`
  width: 100%;
  min-width: ${({ minWidth }) => (!!minWidth ? minWidth : 700)}px;
  display: block;
  //margin-left: 50%;
  //transform: translateX(-50%);
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    min-width: 0;
  }
`;

export default PageHeaderStatic;
