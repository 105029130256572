import React, { useState } from 'react';
import LatestReleases from 'components/LatestReleases';
import FeaturedVideos from 'components/FeaturedVideos';
import FeaturedInteractive from 'components/FeaturedInteractive';
import PageHeaderCopyBlock from 'components/PageHeaderCopyBlock';
import { FlexHorizontal } from 'components/Common';
import Capabilities from 'components/Capabilities';
import PageHeader from 'components/PageHeader';
import { Content } from 'App';

import colors from 'constants/colors';

import PageFooter from 'components/PageFooter';
import { useLocation } from 'react-router-dom';
import Page from 'components/Page';
import { useSelector } from 'react-redux';
import { selectCoreCapabilities, selectHeadersByIds, selectHomeLayout, selectTilesByIds } from 'state/selectors';
import { MainState } from 'state/reducers';

const HomePage: React.FunctionComponent = () => {
  const [page, setPage] = useState(0);
  const [stopAutoRotation, setStopAutoRotation] = useState(false);
  const { pathname } = useLocation();
  const layout = useSelector(selectHomeLayout);
  const latestReleases = useSelector((state: MainState) => selectTilesByIds(state, layout?.latest || []));
  const featuredInteractive = useSelector((state: MainState) => selectTilesByIds(state, layout?.interactive || []));
  const featuredVideos = useSelector((state: MainState) => selectTilesByIds(state, layout?.video || []));
  const headers = useSelector((state: MainState) => selectHeadersByIds(state, layout?.headers || []));

  const onPage = (page: number) => {
    setPage(page);
  };

  const onHeaderBlockMouseEnter = () => {
    setStopAutoRotation(true);
  };

  const onHeaderBlockMouseLeave = () => {
    setStopAutoRotation(false);
  };

  const header = headers[page];

  const capabilities = useSelector(selectCoreCapabilities);

  return (
    <Page key={pathname}>
      <PageHeader onPage={onPage} items={headers} shouldRotate={!stopAutoRotation} />

      <Content className="wideOnly">
        <FlexHorizontal className="wideOnly flexend">
          {!!header && (
            <PageHeaderCopyBlock
              title={header.label}
              copy={header.text}
              color={colors.red}
              link={header.url}
              item={header.tile}
              onMouseEnter={onHeaderBlockMouseEnter}
              onMouseLeave={onHeaderBlockMouseLeave}
            />
          )}

          {!!latestReleases.length && <LatestReleases items={latestReleases} />}
        </FlexHorizontal>
      </Content>

      <Content style={{ marginBottom: 10 }}>
        <FlexHorizontal className="hasTopMargin xtop">
          <Capabilities hideInfo items={capabilities} subHeading="Jump to capabilities" />
        </FlexHorizontal>

        {!!featuredInteractive.length && (
          <FlexHorizontal className="hasTopMargin">
            <FeaturedInteractive items={featuredInteractive} />
          </FlexHorizontal>
        )}

        {!!featuredVideos.length && (
          <FlexHorizontal className="hasTopMargin">
            <FeaturedVideos items={featuredVideos} />
          </FlexHorizontal>
        )}
      </Content>

      <PageFooter />
    </Page>
  );
};

export default HomePage;
