import { Capability } from '../components/Capabilities/types';

import testImg from 'images/assets/Mosaic__Capabilities_S.jpg';
import { ItemSize } from 'components/Item/types';
import { CapabilityType } from 'types';
import { sortBy } from 'lodash';

const getCapabilityImage = (id: number, large?: boolean) => `/images/assets/${id}_Capabilities_${!!large ? 'L' : 'S'}.jpg`;

export const domains: Capability[] = sortBy(
  [
    {
      id: CapabilityType.SPACE,
      label: 'Space',
      img: getCapabilityImage(285),
      imgLarge: getCapabilityImage(285, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.DOMAINS,
      sortOrder: 1,
    },
    {
      id: CapabilityType.AIR,
      label: 'Air',
      img: getCapabilityImage(286),
      imgLarge: getCapabilityImage(286, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.DOMAINS,
      sortOrder: 2,
    },
    {
      id: CapabilityType.LAND,
      label: 'Land',
      img: getCapabilityImage(287),
      imgLarge: getCapabilityImage(287, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.DOMAINS,
      sortOrder: 3,
    },
    {
      id: CapabilityType.SEA,
      label: 'Sea',
      img: getCapabilityImage(288),
      imgLarge: getCapabilityImage(288, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.DOMAINS,
      sortOrder: 4,
    },
    {
      id: CapabilityType.CYBER,
      label: 'Cyber',
      img: getCapabilityImage(289),
      imgLarge: getCapabilityImage(289, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.DOMAINS,
      sortOrder: 5,
    },
    {
      id: CapabilityType.MULTI_DOMAIN,
      label: 'Multi-domain',
      img: getCapabilityImage(290),
      imgLarge: getCapabilityImage(290, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.DOMAINS,
      sortOrder: 6,
    },
  ],
  (i) => i.sortOrder,
);

export const industries: Capability[] = [
  {
    id: CapabilityType.DEFENSE,
    label: 'Defense',
    img: getCapabilityImage(291),
    imgLarge: getCapabilityImage(291, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.INDUSTRIES,
    sortOrder: 1,
  },
  {
    id: CapabilityType.COMMERCIAL,
    label: 'Commercial',
    img: getCapabilityImage(292),
    imgLarge: getCapabilityImage(292, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.INDUSTRIES,
    sortOrder: 2,
  },
  {
    id: CapabilityType.CIVIL,
    label: 'Civil',
    img: getCapabilityImage(293),
    imgLarge: getCapabilityImage(293, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.INDUSTRIES,
    sortOrder: 3,
  },
];

export const solutions: Capability[] = sortBy(
  [
    {
      id: CapabilityType.ELECTRONIC_WARFARE,
      label: 'ELECTRONIC WARFARE',
      img: getCapabilityImage(297),
      imgLarge: getCapabilityImage(297, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.INTEGRATED_MARITIME_SOLUTIONS,
      label: 'Integrated Maritime Solutions',
      img: getCapabilityImage(298),
      imgLarge: getCapabilityImage(298, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.INTEGRATED_VISION_SOLUTIONS,
      label: 'Integrated Vision Solutions',
      img: getCapabilityImage(299),
      imgLarge: getCapabilityImage(299, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.ISR_AND_SIGINT,
      label: 'ISR AND SIGINT',
      img: getCapabilityImage(300),
      imgLarge: getCapabilityImage(300, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.MISSILE_WARNING_AND_DEFENSE,
      label: 'MISSILE WARNING AND DEFENSE',
      img: getCapabilityImage(301),
      imgLarge: getCapabilityImage(301, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.PRECISION_MUNITIONS,
      label: 'PRECISION MUNITIONS',
      img: getCapabilityImage(302),
      imgLarge: getCapabilityImage(302, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.PUBLIC_SAFETY_AND_CRITICAL,
      label: 'PUBLIC SAFETY AND CRITICAL COMMUNICATIONS',
      img: getCapabilityImage(303),
      imgLarge: getCapabilityImage(303, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.RESILIENT_COMMUNICATIONS_AND_NETWORKS_SOLUTIONS,
      label: 'RESILIENT COMMUNICATIONS AND NETWORKS',
      img: getCapabilityImage(260),
      imgLarge: getCapabilityImage(260),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.SOFTWARE_AND_CYBER_SOLUTIONS,
      label: 'Software and Cyber Solutions',
      img: getCapabilityImage(305),
      imgLarge: getCapabilityImage(305, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.SPACE_SUPERIORITY,
      label: 'SPACE SUPERIORITY',
      img: getCapabilityImage(306),
      imgLarge: getCapabilityImage(306, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.TRAINING_AND_SIMULATION_SYSTEMS,
      label: 'Training and Simulation Systems',
      img: getCapabilityImage(307),
      imgLarge: getCapabilityImage(307, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.UNDERSEA_WARFARE,
      label: 'UNDERSEA WARFARE',
      img: getCapabilityImage(308),
      imgLarge: getCapabilityImage(308, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.AUTONOMOUS_SYSTEMS_SOLUTIONS,
      label: 'Autonomous Systems',
      img: getCapabilityImage(294),
      imgLarge: getCapabilityImage(294, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.COMMAND_AND_CONTROL_SOLUTIONS,
      label: 'COMMAND AND CONTROL',
      img: getCapabilityImage(296),
      imgLarge: getCapabilityImage(296, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
    {
      id: CapabilityType.AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS,
      label: 'AIRCRAFT MISSIONIZATION AND AVIONICS',
      img: getCapabilityImage(295),
      imgLarge: getCapabilityImage(295, true),
      size: ItemSize.LARGE,
      parent: CapabilityType.SOLUTIONS,
    },
  ],
  (o) => o.label,
);

export const worldDefenseShow: Capability[] = [
  {
    id: CapabilityType.WDS_C5ISR_SOLUTIONS,
    label: 'C5ISR SOLUTIONS',
    img: getCapabilityImage(309),
    imgLarge: getCapabilityImage(309, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.WORLD_DEFENSE_SHOW_2022,
    sortOrder: 2,
    isTradeShow: true,
  },
  {
    id: CapabilityType.WDS_COMMERCIAL_AVIATION_SOLUTIONS,
    label: 'COMMERCIAL AVIATION SOLUTIONS',
    img: getCapabilityImage(310),
    imgLarge: getCapabilityImage(310, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.WORLD_DEFENSE_SHOW_2022,
    sortOrder: 6,
    isTradeShow: true,
  },
  {
    id: CapabilityType.WDS_EOIR_SENSOR_SOLUTIONS,
    label: 'EO/IR SENSOR SOLUTIONS',
    img: getCapabilityImage(311),
    imgLarge: getCapabilityImage(311, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.WORLD_DEFENSE_SHOW_2022,
    sortOrder: 4,
    isTradeShow: true,
  },
  {
    id: CapabilityType.WDS_EW_AND_CYBER_SOLUTIONS,
    label: 'EW AND CYBER SOLUTIONS',
    img: getCapabilityImage(312),
    imgLarge: getCapabilityImage(312, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.WORLD_DEFENSE_SHOW_2022,
    sortOrder: 5,
    isTradeShow: true,
  },
  {
    id: CapabilityType.WDS_ISR_AND_SIGINT_SOLUTIONS,
    label: 'ISR AND SIGINT SOLUTIONS',
    img: getCapabilityImage(313),
    imgLarge: getCapabilityImage(313, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.WORLD_DEFENSE_SHOW_2022,
    sortOrder: 1,
    isTradeShow: true,
  },
  {
    id: CapabilityType.WDS_MARITIME_AND_AUTONOMOUS_SOLUTIONS,
    label: 'MARITIME AND AUTONOMOUS SOLUTIONS',
    img: getCapabilityImage(314),
    imgLarge: getCapabilityImage(314, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.WORLD_DEFENSE_SHOW_2022,
    sortOrder: 3,
    isTradeShow: true,
  },
];

export const spaceSymposium: Capability[] = [
  {
    id: CapabilityType.SS_SPACE_AS_A_WARFIGHTING_DOMAIN,
    label: 'Space as a Warfighting Domain',
    img: getCapabilityImage(315),
    imgLarge: getCapabilityImage(315, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.SPACE_SYMPOSIUM,
    isTradeShow: true,
  },
  {
    id: CapabilityType.SS_WEATHER,
    label: 'Weather',
    img: getCapabilityImage(316),
    imgLarge: getCapabilityImage(316, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.SPACE_SYMPOSIUM,
    isTradeShow: true,
  },
  {
    id: CapabilityType.SS_LAUNCH,
    label: 'Launch',
    img: getCapabilityImage(317),
    imgLarge: getCapabilityImage(317, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.SPACE_SYMPOSIUM,
    isTradeShow: true,
  },
  {
    id: CapabilityType.SS_POSITIONING_NAVIGATION_TIMING,
    label: 'POSITIONING, NAVIGATION & TIMING',
    img: getCapabilityImage(318),
    imgLarge: getCapabilityImage(318, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.SPACE_SYMPOSIUM,
    isTradeShow: true,
  },
];

export const navyLeague: Capability[] = [
  {
    id: CapabilityType.NL_ISR_AND_SIGINT_SOLUTIONS,
    label: 'ISR AND SIGINT SOLUTIONS',
    img: getCapabilityImage(319),
    imgLarge: getCapabilityImage(319, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.NAVY_LEAGUE,
    isTradeShow: true,
  },
  {
    id: CapabilityType.NL_RESILIENT_COMMUNICATIONS_AND_NETWORKS_SOLUTIONS,
    label: 'RESILIENT COMMUNICATIONS AND NETWORKS SOLUTIONS',
    img: getCapabilityImage(320),
    imgLarge: getCapabilityImage(320, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.NAVY_LEAGUE,
    isTradeShow: true,
  },
  {
    id: CapabilityType.NL_MARITIME_AND_AUTONOMOUS_SYSTEMS,
    label: 'MARITIME AND AUTONOMOUS SYSTEMS',
    img: getCapabilityImage(321),
    imgLarge: getCapabilityImage(321, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.NAVY_LEAGUE,
    isTradeShow: true,
  },
  {
    id: CapabilityType.NL_AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS,
    label: 'AIRCRAFT MISSIONIZATION AND AVIONICS SOLUTIONS',
    img: getCapabilityImage(322),
    imgLarge: getCapabilityImage(322, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.NAVY_LEAGUE,
    isTradeShow: true,
  },
  {
    id: CapabilityType.NL_ELECTRONIC_WARFARE_SOLUTIONS,
    label: 'ELECTRONIC WARFARE SOLUTIONS',
    img: getCapabilityImage(323),
    imgLarge: getCapabilityImage(323, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.NAVY_LEAGUE,
    isTradeShow: true,
  },
  {
    id: CapabilityType.NL_DISTRIBUTED_MARITIME_OPERATIONS,
    label: 'DISTRIBUTED MARITIME OPERATIONS',
    img: getCapabilityImage(324),
    imgLarge: getCapabilityImage(324, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.NAVY_LEAGUE,
    isTradeShow: true,
  },
];

export const tradeShows: Capability[] = [
  {
    id: CapabilityType.WORLD_DEFENSE_SHOW_2022,
    label: 'World Defense Show 2022',
    img: getCapabilityImage(327),
    imgLarge: getCapabilityImage(327, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.TRADE_SHOWS,
    relation: worldDefenseShow,
    isTradeShow: true,
    info: {
      title: '6 – 9 March 2022',
      subtitle: 'Riyadh, Saudi Arabia',
    },
  },
  {
    id: CapabilityType.SPACE_SYMPOSIUM,
    label: 'Space Symposium',
    img: getCapabilityImage(326),
    imgLarge: getCapabilityImage(326, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.TRADE_SHOWS,
    relation: spaceSymposium,
    isTradeShow: true,
    hideInNav: true,
  },
  {
    id: CapabilityType.NAVY_LEAGUE,
    label: 'Navy League',
    img: getCapabilityImage(325),
    imgLarge: getCapabilityImage(325, true),
    size: ItemSize.LARGE,
    parent: CapabilityType.TRADE_SHOWS,
    relation: navyLeague,
    isTradeShow: true,
    hideInNav: true,
  },
];

const capabilities: Capability[] = [
  {
    id: CapabilityType.DOMAINS,
    label: 'Domains',
    img: getCapabilityImage(281),
    imgLarge: getCapabilityImage(281, true),
    relation: domains,
    size: ItemSize.LARGE,
  },
  {
    id: CapabilityType.INDUSTRIES,
    label: 'Industries',
    img: getCapabilityImage(282),
    imgLarge: getCapabilityImage(282, true),
    relation: industries,
    size: ItemSize.LARGE,
  },
  {
    id: CapabilityType.SOLUTIONS,
    label: 'Solutions',
    img: getCapabilityImage(283),
    imgLarge: getCapabilityImage(283, true),
    relation: solutions,
    size: ItemSize.LARGE,
  },
  {
    id: CapabilityType.TRADE_SHOWS,
    label: 'Trade shows',
    img: getCapabilityImage(284),
    imgLarge: getCapabilityImage(284, true),
    relation: tradeShows,
    size: ItemSize.LARGE,
  },
];

export default capabilities;
