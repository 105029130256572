import colors from 'constants/colors';
import React from 'react';
import styled from 'styled-components';

import chevron from 'images/icon.chevron.svg';

type Props = {
  onClick: () => void;
};

const NextButton: React.FunctionComponent<Props> = (props) => (
  <Container onClick={props.onClick}>
    <img src={chevron} width={18} height={29} />
  </Container>
);

export const PrevButton: React.FunctionComponent<Props> = (props) => (
  <Container onClick={props.onClick} flip>
    <img src={chevron} width={18} height={29} />
  </Container>
);

const Container = styled.div<{ flip?: boolean }>`
  background-color: ${colors.red};
  width: 56px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 18px;
  cursor: pointer;
  transition: padding 0.25s ease-in-out;
  ${({ flip }) => !!flip && `transform: rotate(180deg);`}
  &:hover {
    padding-right: 10px;
  }
`;

export default NextButton;
