import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ScaledSubHeading } from 'components/Type';
import Carousel from 'components/Carousel';
import { ApiItem, Item, ItemSize } from 'components/Item/types';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';

type Props = {
  items: ApiItem[];
};

const Featured: React.FunctionComponent<Props> = ({ items }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  const { isMobile, isTablet } = useIsDevice();

  const measure = () => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  };

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    const to = setTimeout(() => measure(), 100);
    return () => clearTimeout(to);
  }, [ref]);

  useLayoutEffect(() => {
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  });

  const numItemsDisplay = isMobile ? (isTablet ? 2.5 : 1.5) : 4;

  return (
    <Container ref={ref}>
      <ScaledSubHeading marginTop={4}>Featured</ScaledSubHeading>

      <Carousel
        size={ItemSize.SMALL}
        width={width}
        items={items}
        numItemsDisplay={numItemsDisplay}
        //minWidth
        //maxWidth
        noPrevious
        name="featured"
        hidePagination
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  margin: 10px 0 0 10px;
  position: relative;
  min-width: 0;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    margin: 20px 20px 0;
    display: block;
  }
`;

export default Featured;
