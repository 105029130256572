import colors from 'constants/colors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApiItem, ApiItemType, ItemSize } from 'components/Item/types';
import ItemComp from 'components/Item';
import { SubHeading } from 'components/Type';
import useWindowSize from 'hooks/useWindowSize';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';
import { useSelector } from 'react-redux';
import { selectTiles } from 'state/selectors';

type Props = {
  size: number;
};

const SearchBar: React.FunctionComponent<Props> = ({ size }) => {
  const [input, setInput] = useState('');
  const [results, setResults] = useState<ApiItem[]>([]);
  const windowWidth = useWindowSize().width;
  const { isMobile } = useIsDevice();
  const width = isMobile ? windowWidth - 80 : size - 200;

  const items = useSelector(selectTiles).filter((c) => [ApiItemType.INTERACTIVE, ApiItemType.VIDEO, ApiItemType.POPUP].includes(c.type));

  const RESULT_GAP = isMobile ? 5 : 10;
  const RESULT_WIDTH = (width - RESULT_GAP) / 2;
  const POPUP_RESULT_WIDTH = (width - RESULT_GAP * 3) / (isMobile ? 3 : 4);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  useEffect(() => {
    if (input.length < 2) {
      return setResults([]);
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'search', content: `${input}` });

    const escapeRegExp = (string: String) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    //const found = items.filter((item, idx) => !!item.label && item.label.toLowerCase().startsWith(input.toLowerCase()));
    //const regEx = new RegExp(String.raw`(?:^|\s)(${escapeRegExp(input)}([a-z0-9]?)\w*)`, 'gi');
    const regEx = new RegExp(String.raw`(.*?)(${escapeRegExp(input)})(.*?)`, 'i');
    const found = items.filter((item, idx) => {
      if (!item.label) return false;
      const test = regEx.test(item.label);
      const test2 = regEx.test(item.metaTags);
      return test || test2;
    });
    setResults(found);
  }, [input]);

  const interactiveResults = results.filter((result) => result.type === ApiItemType.INTERACTIVE);
  const videoResults = results.filter((result) => result.type === ApiItemType.VIDEO);
  const popupResults = results.filter((result) => result.type === ApiItemType.POPUP);

  return (
    <Container className="searchbar">
      <InputWrap>
        <Input value={input} onChange={onInputChange} placeholder="Search term" autoFocus />
      </InputWrap>
      <Scrollable>
        {!!interactiveResults.length && (
          <>
            <ResultHeading>Interactive Experiences</ResultHeading>
            <Results>
              {interactiveResults.map((result, idx) => (
                <ItemComp
                  key={`interactive-result-${idx}`}
                  {...result}
                  width={RESULT_WIDTH}
                  marginRight={idx % 2 === 1 ? 0 : RESULT_GAP}
                  marginBottom={isMobile ? RESULT_GAP * 2 : RESULT_GAP}
                  hideIcons
                  size={ItemSize.SMALL}
                />
              ))}
            </Results>
          </>
        )}
        {!!videoResults.length && (
          <>
            <ResultHeading>Videos</ResultHeading>
            <Results>
              {videoResults.map((result, idx) => (
                <ItemComp
                  key={`video-result-${idx}`}
                  {...result}
                  width={RESULT_WIDTH}
                  marginRight={idx % 2 === 1 ? 0 : RESULT_GAP}
                  marginBottom={isMobile ? RESULT_GAP * 2 : RESULT_GAP}
                  hideIcons
                  size={ItemSize.SMALL}
                />
              ))}
            </Results>
          </>
        )}
        {!!popupResults.length && (
          <>
            <ResultHeading>Additional Capabilities</ResultHeading>
            <Results className="textUnder">
              {popupResults.map((result, idx) => (
                <ItemComp
                  key={`popup-result-${idx}`}
                  {...result}
                  width={POPUP_RESULT_WIDTH}
                  marginRight={idx % 4 === 3 ? 0 : RESULT_GAP}
                  marginBottom={isMobile ? RESULT_GAP * 2 : RESULT_GAP}
                  hideIcons
                  size={ItemSize.SMALL}
                />
              ))}
            </Results>
          </>
        )}
      </Scrollable>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    width: 100%;
    //padding-top: 80px;
    position: relative;
  }
`;

const InputWrap = styled.div`
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    position: fixed;
    left: 30px;
    right: 30px;
    margin-top: 18px;
    top: 50px;
    z-index: 2;
    background-color: ${colors.customFade.darkGrey(1)};
  }
`;

const Input = styled.input`
  display: block;
  background: none;
  border: none;
  border-bottom: 1px solid ${colors.white};
  width: 100%;
  color: ${colors.white};
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  padding-bottom: 2px;
  border-radius: 0;
  overflow: hidden;
  &:focus {
    outline: none;
  }
`;

const ResultHeading = styled(SubHeading)`
  color: ${colors.navGrey};
  font-size: 16px;
  font-size: 0.85vw;
  font-size: clamp(16px, 0.85vw, 24px);
`;

const Results = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

const Scrollable = styled.div`
  // overflow-y: auto;
  // -webkit-overflow-scrolling: touch;
  height: 100%;
  padding-bottom: 50px;
  //padding-top: 80px;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media (min-width: ${mobile.MAX_WIDTH}px) {
    overflow-y: auto;
  }
`;

export default SearchBar;
