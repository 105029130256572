import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import colors from 'constants/colors';
import HeaderGradient from '../HeaderGradient';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';

const AUTO_CAROUSEL = 10000;

type Props = {
  onPage?: (index: number) => void;
  items: { img: string; imgLarge: string; imgMobile: string; id: number }[];
  shouldRotate?: boolean;
};

type TimeOut = ReturnType<typeof setTimeout>;

const PageHeader: React.FunctionComponent<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [page, setPage] = useState(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [shouldRotate, setShouldRotate] = useState(true);
  const timerRef = useRef<TimeOut | null>(null);

  const { isMobile } = useIsDevice();
  const NUM_PAGES = props.items.length;
  const position = page * width;

  useEffect(() => {
    if (props.onPage) {
      props.onPage(page);
    }
    if (props.shouldRotate && shouldRotate) {
      onTimer();
    } else {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
    return () => clearTimeout(timerRef.current as TimeOut);
  }, [props.shouldRotate, shouldRotate, page]);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useLayoutEffect(() => {
    const measure = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  });

  const onTimer = () => {
    if (props.shouldRotate && shouldRotate) {
      timerRef.current = setTimeout(() => onNext(), AUTO_CAROUSEL);
    }
  };

  const onNext = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setShouldAnimate(true);
    setTimeout(() => setShouldAnimate(false), 500);
    if (page >= NUM_PAGES - 1) {
      setPage(0);
      return;
    }
    setPage(page + 1);
  };

  const ratio = isMobile ? 450 / mobile.MAX_WIDTH : 700 / 1920;

  return (
    <Container ref={ref} height={width * ratio} onMouseEnter={() => setShouldRotate(false)} onMouseLeave={() => setShouldRotate(true)}>
      <HeaderGradient />
      <Slideable style={{ width: width * NUM_PAGES, transform: `translateX(-${position}px)` }} shouldAnimate={shouldAnimate}>
        {props.items.map((item, idx) => {
          const nonFirstItemStyles = {
            top: 0,
            left: idx * width,
          };
          return (
            <SetContainer
              style={{ width, position: idx === 0 ? 'relative' : 'absolute', ...nonFirstItemStyles }}
              key={`header-slide-${idx}`}
            >
              <HeaderImage src={isMobile ? item.imgMobile : width > 1921 ? item.imgLarge : item.img} />
            </SetContainer>
          );
        })}
        <span style={{ clear: 'both' }} />
      </Slideable>
    </Container>
  );
};

const Container = styled.div<{ height: number }>`
  width: 100%;
  background-color: black;
  position: relative;
  white-space: nowrap;
  //height: ${({ height }) => height}px;
  //min-height: 275px;
  // @media (max-width: ${mobile.MAX_WIDTH}px) {
  //   min-height: auto;
  // }
`;

const Slideable = styled.div<{ shouldAnimate?: boolean }>`
  transform: translateX(0%);
  ${({ shouldAnimate }) =>
    !!shouldAnimate &&
    `
    transition: transform 0.4s ease-in-out;
  `}
`;

const SetContainer = styled.div`
  pointer-events: none;
`;

// const HeaderImage2 = styled.div<{ src: string }>`
//   background-image: url(${({ src }) => src})};
//   background-size: cover;
//   background-position: center center;
//   width: 100%;
//   height: 394px;
// `;

const HeaderImage = styled.img`
  width: 100%;
  min-width: 1320px;
  display: block;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    min-width: auto;
  }
`;

const Next = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateY(-50%);
  cursor: pointer;
`;
const Previous = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Pagination = styled.div`
  position: absolute;
  bottom: 20px;
  right: 105px;
  display: flex;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    right: 10px;
    bottom: 10px;
  }
`;
export const Dot = styled.div<{ active?: boolean }>`
  width: 10px;
  height: 10px;
  margin-left: 10px;
  border-radius: 5px;
  background-color: ${({ active }) => (!!active ? colors.red : colors.white)};
  cursor: pointer;
`;

export default PageHeader;
