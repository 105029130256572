import mobile from 'constants/mobile';
import styled from 'styled-components';

export const FlexHorizontal = styled.div`
  display: flex;

  &.flexend {
    align-items: flex-end;
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      align-items: flex-start;
    }
  }
  &.hasTopMargin {
    //margin-top: 52px;
    margin-top: 26px;
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      margin-top: 10px;
    }
  }
  &.xtop {
    margin-top: 38px;
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      margin-top: 10px;
    }
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: block;
    //flex-direction: column;
    margin-top: 0vw;
  }
`;

export const Relative = styled.div`
  position: relative;
  width: 100%;
`;
