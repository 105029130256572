import { CapabilityTag } from 'types';

export type Item = {
  id: string;
  link: string;
  content: string;

  label?: string;
  size: ItemSize;
  type: ItemType;

  capabilityTags: CapabilityTag[];
  worldDefenseShowTags: string[];
  spaceSymposiumTags: string[];
  navyLeagueTags: string[];
};

export type ApiItem = {
  id: number;
  label: string;
  type: ApiItemType;
  link: string;
  tags: number[];
  img: string;
  imgLarge: string;
  additionalContent: string;
  additionalContentTitle: string;
  additionalImg: string;
  additionalImgLarge: string;
  additionalImgMobile: string;
  metaTags: string;
};

// id: original["ID"],
// label: original["Descriptive Title"],
// link: original["Link if applicable"],
// content: original["Content"],
// type: original["Content Type"],
// capabilityTags,
// worldDefenseShowTags,
// spaceSymposiumTags,
// navyLeagueTags,

export enum ItemSize {
  SQUARE = 'square',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ItemType {
  VIDEO = 'video',
  INTERACTIVE = 'interactive',
  POPUP = 'popup',
  FOOTER_LINK = 'footer_link',
}

export enum ApiItemType {
  INTERACTIVE = 'ceros',
  VIDEO = 'video',
  POPUP = 'additional',
}
