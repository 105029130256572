import { all, put, takeEvery } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import api from '../api';

function* getTiles({ payload }: ActionType<typeof actions.getTiles.request>) {
  const response = yield* call(api.getTiles);
  yield put(actions.getTiles.success(response));
}

function* getCapabilities({ payload }: ActionType<typeof actions.getCapabilities.request>) {
  const response = yield* call(api.getCapabilities, payload.isStaging);
  yield put(actions.getCapabilities.success(response));
}

function* getLayouts({ payload }: ActionType<typeof actions.getLayouts.request>) {
  const response = yield* call(api.getLayouts, payload.isStaging);
  yield put(actions.getLayouts.success(response));
}

function* getHeaders({ payload }: ActionType<typeof actions.getHeaders.request>) {
  const response = yield* call(api.getHeaders, payload.isStaging);
  yield put(actions.getHeaders.success(response));
}

function* getTradeShows({ payload }: ActionType<typeof actions.getTradeShows.request>) {
  const response = yield* call(api.getTradeshows, payload.isStaging);
  yield put(actions.getTradeShows.success(response));
}

export default function* rootSaga() {
  yield all([
    takeEvery(getType(actions.getTiles.request), getTiles),
    takeEvery(getType(actions.getCapabilities.request), getCapabilities),
    takeEvery(getType(actions.getLayouts.request), getLayouts),
    takeEvery(getType(actions.getHeaders.request), getHeaders),
    takeEvery(getType(actions.getTradeShows.request), getTradeShows),
  ]);
}
