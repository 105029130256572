import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import logo from 'images/logo.l3.png';
import mobile from 'constants/mobile';

type Props = {
  footerLink?: string;
  footerLinkText?: string;
};

const defaultLink = 'https://www.l3harris.com';
const defaultText = 'VISIT L3HARRIS.COM';

const PageFooter: React.FunctionComponent<Props> = (props) => {
  const footerLink = props.footerLink || defaultLink;
  const text = props.footerLinkText || defaultText;

  return (
    <Container>
      <Content>
        <LegalWrap>
          <Legal>The appearance of U.S. Department of Defense (DoD) visual information does not imply or constitute DoD endorsement</Legal>
          <Legal style={{ marginTop: 4 }}>
            <a href="https://www.l3harris.com/en-gb/privacy-policy" target="blank">
              Privacy policy
            </a>{' '}
            |{' '}
            <a href="https://www.l3harris.com/en-gb/terms" target="_blank">
              Terms of use
            </a>
          </Legal>
        </LegalWrap>
        <Visit href={footerLink} target="_blank">
          &gt; {text}
        </Visit>
      </Content>
    </Container>
  );
};

export default PageFooter;

const Container = styled.div`
  width: 100%;
  height: 110px;
  background-color: ${colors.background};
  box-shadow: 0px -7px 10px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    height: auto;
    padding: 40px 0;
  }
`;

const Content = styled.div`
  padding: 0 105px 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: block;
    padding: 0 20px;
  }
`;

const Logo = styled.img`
  display: block;
`;

const Visit = styled.a`
  color: ${colors.white};
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    padding: 0;
    font-size: 2vw;
    font-size: clamp(11px, 1.5vw, 13px);
  }
`;

const LegalWrap = styled.div`
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    margin-bottom: 20px;
  }
`;
const Legal = styled.p`
  font-size: 11px;
  padding: 0 20px;
  color: #737373;
  text-align: left;
  opacity: 0.5;
  a {
    color: #737373;
    &:hover {
      color: #999;
    }
  }
  margin-bottom: 10px;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    padding: 0;
    font-size: 1.5vw;
    font-size: clamp(11px, 1.5vw, 13px);
  }
`;
