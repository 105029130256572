import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { chunk } from 'lodash';
import Item from 'components/Item';
import { ApiItem, ApiItemType, Item as ItemType, ItemSize } from 'components/Item/types';
import constants from 'constants/index';
import NextButton, { PrevButton } from 'components/NextButton';
import colors from 'constants/colors';
import PaginationSlider from '../PaginationSlider';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';

type Props = {
  size: ItemSize;
  width: number;
  items: ApiItem[];
  numItemsDisplay?: number;
  minWidth?: boolean;
  maxWidth?: boolean;
  hideIcons?: boolean;
  noPrevious?: boolean;
  debug?: boolean;
  name?: string;
  hidePagination?: boolean;
  hasFeaturedItem?: boolean;
};

const Carousel: React.FunctionComponent<Props> = (props) => {
  const [page, setPage] = useState(1);
  const sliderRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useIsDevice();
  const noPrevious = isMobile ? false : props.noPrevious;

  const availableWidth = props.width; // - 3?

  const minWidth = !isMobile && props.minWidth ? props.minWidth : false;
  const maxWidth = !isMobile && props.maxWidth ? props.maxWidth : false;
  const hasDifferentSizeItems = isMobile ? false : props.hasFeaturedItem; //!props.items.every((v) => v.size === props.items[0].size);

  const numItemsDisplay = minWidth && maxWidth ? Math.floor(availableWidth / 343) : props.numItemsDisplay || 5; // 343 = small size + padding

  const chunkedItems = chunk(props.items, hasDifferentSizeItems ? numItemsDisplay * 2 : numItemsDisplay);
  const chunkWidth = availableWidth;

  const totalSpacingPerChunk = (numItemsDisplay - 1) * constants.ITEM_SPACING;

  const itemWidth = (chunkWidth - totalSpacingPerChunk) / numItemsDisplay;

  const numberOfPages = chunkedItems.length;

  const debugObj = {
    name: props.name,
    numItemsDisplay,
    itemWidth,
    chunkWidth,
    totalSpacingPerChunk,
    numberOfPages,
    numItems: props.items.length,
    hasDifferentSizeItems,
  };
  //console.log(debugObj);

  const onNext = () => {
    if (page < numberOfPages) {
      setPage(page + 1);
    } else {
      setPage(1);
    }
  };
  const onPrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const getPagePosition = () => {
    if (!sliderRef.current) return 0;
    if (page === 1) return 0;
    const children = sliderRef.current.childNodes;
    const isSingleItemSlide = numItemsDisplay === 1;

    const totalWidth = [...new Array(children.length)].reduce((acc, _, idx) => {
      const child = children[idx] as HTMLDivElement;
      const shouldSkip = isSingleItemSlide && page !== numberOfPages ? idx + 2 > page : idx + 1 > page;
      return shouldSkip ? acc : acc + child.getBoundingClientRect().width;
    }, 0);

    return isSingleItemSlide && page !== numberOfPages ? totalWidth + 3 * page : totalWidth - chunkWidth + 3 * (page - 1);
  };

  const position = getPagePosition();

  const showPrev = !noPrevious && page > 1;
  const showNext = (noPrevious && numberOfPages > 1) || (numberOfPages > 1 && page < numberOfPages);

  const renderSingleRows = () =>
    chunkedItems.map((chunk, cidx) => (
      <Chunk className="chunk" key={`chnk-${cidx}`} noMargin={cidx === chunkedItems.length - 1}>
        {chunk.map((item, idx) => renderItem(item))}
      </Chunk>
    ));

  const renderDoubleRows = () =>
    chunkedItems.map((chunk, cidx) => (
      <Chunk className="chunk" key={`chnk-${cidx}`} noMargin={cidx === chunkedItems.length - 1}>
        {renderDoubleRow(chunk)}
      </Chunk>
    ));

  const renderDoubleRow = (chunk: ApiItem[]) => {
    let i = 0;
    let elements = [];
    while (i < chunk.length && i < constants.MAX_SAFETY) {
      const item = chunk[i];
      const nextItem = i < chunk.length - 1 ? props.items[i + 1] : false;

      if (i === 0) {
        // changed from size !== SMALL
        elements.push(renderItem(item, true));
        i++;
      } else {
        elements.push(
          <TwoRowItemContainer key={`two-row-${i}`}>
            {renderItem(item)}
            {!!nextItem && renderItem(nextItem)}
          </TwoRowItemContainer>,
        );
        i += 2;
      }
    }
    return elements;
  };

  const renderItem = (item: ApiItem, isFeatured?: boolean) => (
    <Item
      key={`item-${item.id}`}
      {...item}
      width={itemWidth}
      minWidth={minWidth}
      maxWidth={maxWidth}
      hideIcons={props.hideIcons}
      //size={isMobile ? ItemSize.SMALL : item.size}
      size={isMobile ? ItemSize.SMALL : item.type === ApiItemType.POPUP ? ItemSize.SQUARE : isFeatured ? ItemSize.MEDIUM : ItemSize.SMALL}
      isMobile={isMobile}
    />
  );

  return (
    <Relative>
      <Container>
        <Slider style={{ transform: `translate(-${position}px)` }} ref={sliderRef} className="slider">
          {hasDifferentSizeItems ? renderDoubleRows() : renderSingleRows()}
        </Slider>
      </Container>

      {numberOfPages > 1 && <PaginationSlider page={page} numberOfPages={numberOfPages} hide={props.hidePagination} />}

      <Control direction="next" className={classNames({ active: !!showNext })}>
        <NextButton onClick={onNext} />
      </Control>

      <Control direction="prev" className={classNames({ active: !!showPrev })}>
        <PrevButton onClick={onPrev} />
      </Control>
    </Relative>
  );
};

const Relative = styled.div`
  position: relative;
`;
const Control = styled.div<{ direction: 'prev' | 'next' }>`
  position: absolute;
  top: 50%;
  transform: translateX(${({ direction }) => (direction === 'next' ? '50%' : '-50%')}) translateY(-50%);
  ${({ direction }) => (direction === 'next' ? `right: 0` : `left: 0`)};
  z-index: 1;
  display: none;
  &.active {
    display: block;
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      display: none;
      > div {
        background-color: ${colors.red};
        opacity: 1;
        pointer-events: auto;
        display: none;
      }
    }
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: none;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    width: auto;
    transform: none;
    display: inline-block;
    float: right;
    margin-top: 10px;
    margin-left: 10px;
    > div {
      background-color: ${colors.navGrey};
      opacity: 0.1;
      pointer-events: none;
      display: none;
    }
  }
`;
const Container = styled.div`
  overflow: hidden;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    clip-path: inset(0 0 10px 0);
  }
`;
const Slider = styled.div`
  display: flex;
  white-space: nowrap;
  transition: transform 0.5s ease-in-out;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    transition: none;
    overflow-y: scroll;
    padding-bottom: 20px;
  }
`;
const Chunk = styled.div<{ noMargin?: boolean }>`
  display: flex;
  margin-right: ${({ noMargin }) => (!!noMargin ? 0 : 3)}px;
`;
const TwoRowItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default Carousel;
