import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { chunk } from 'lodash';

import NextButton, { PrevButton } from 'components/NextButton';
import CapabilityItem from 'components/CapabilityItem';
import { ApiCapability } from 'components/Capabilities/types';
import { Dot } from 'components/PageHeader';
import classNames from 'classnames';
import mobile from 'constants/mobile';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import useIsDevice from 'hooks/useIsDevice';

type Props = {
  width: number;
  items: ApiCapability[];
  hideInfo?: boolean;
  baseUrl?: string;
};

const CapabilityCarousel: React.FunctionComponent<Props> = (props) => {
  const qs = queryString.parse(window.location.search);
  // @ts-ignore
  const defaultPage = !!qs.page ? parseInt(qs.page) : 0;

  const [page, setPage] = useState(defaultPage);
  const [position, setPosition] = useState(0);
  const navigate = useNavigate();

  const { isMobile, isTablet } = useIsDevice();

  const perPage = isMobile ? (isTablet ? 3 : 2) : 4;

  useEffect(() => {
    gotoPage(defaultPage);
  }, [props.width]);

  const getContentWidth = useCallback(() => {
    return (props.width / perPage) * props.items.length;
  }, [props.items, props.width]);

  const MAX_WIDTH = getContentWidth();
  const NUM_PAGES = Math.ceil(MAX_WIDTH / props.width) - 1;
  const LAST_PAGE_FRACTION = (MAX_WIDTH / props.width) % 1;

  if (page > 0 && page > NUM_PAGES) {
    setPage(NUM_PAGES);
  }

  useEffect(() => {
    if (NUM_PAGES > 0) {
      navigate(`?page=${page}`, { replace: true });
    }
  }, [page]);

  const onNext = () => {
    gotoPage(page + 1);
  };

  const onPrev = () => {
    gotoPage(page - 1);
  };

  const gotoPage = (goto: number) => {
    const newPage = goto > NUM_PAGES ? 0 : goto < 0 ? NUM_PAGES : goto;

    setPage(newPage);
    if (newPage === NUM_PAGES) {
      setPosition((newPage - 1 + LAST_PAGE_FRACTION) * props.width);
    } else {
      setPosition(newPage * props.width);
    }
  };

  const renderItems = useCallback(() => {
    const chunks = isMobile ? [props.items] : chunk(props.items, perPage);
    const perItemWidth = perPage === 2 ? props.width / 1.5 : perPage === 3 ? props.width / 2.5 : props.width / perPage;
    const useLargeImage = perItemWidth > 425;

    const setWidth = props.width;
    const widthStyle = { width: setWidth, minWidth: setWidth };

    const forceWidth = isMobile ? perItemWidth : 0;

    return chunks.map((chnk, i) => (
      <Set
        style={widthStyle}
        key={`chnk-${i}`}
        className={classNames('set', { hasMargin: !isMobile && chnk.length < perPage, loneItem: isMobile && chnk.length === 1 })}
      >
        {chnk.map((item, i) => (
          <CapabilityItem
            {...item}
            $margin={i !== 0}
            key={`item-${i}`}
            hideInfo={props.hideInfo}
            useLargeImage={useLargeImage}
            forceWidth={forceWidth}
          />
        ))}
        {!isMobile &&
          [...new Array(perPage - chnk.length)].map((dmy, i) => (
            <CapabilityItem
              label="DUMMY"
              key={`dummy-${i}`}
              id={i}
              parentTagId={0}
              tagId={0}
              img=""
              imgLarge=""
              hideInfo={props.hideInfo}
              url=""
              urlTitle=""
              featured={[]}
              heroSubTitle=""
              heroCopy=""
              heroImg=""
              heroImgLarge=""
              heroImgMobile=""
              tradeShowTagId={0}
              footerUrl=""
            />
          ))}
      </Set>
    ));
  }, [props.items, props.width, isMobile, isTablet]);

  const hasPages = NUM_PAGES > 0;
  const hasNextPage = page < NUM_PAGES;
  const hasPrevPage = page > 0;

  return (
    <Wrap>
      {hasPrevPage && (
        <Previous>
          <PrevButton onClick={onPrev} />
        </Previous>
      )}
      <Container className={classNames({ isMobile, isTablet })}>
        <Slide {...props} style={!isMobile ? { transform: `translateX(-${position}px)` } : {}} className="slider">
          {renderItems()}
        </Slide>
      </Container>
      {hasNextPage && (
        <ButtonPositioner>
          <Next>
            <NextButton onClick={onNext} />
          </Next>
        </ButtonPositioner>
      )}
      {hasPages && (
        <Pagination>
          {[...new Array(NUM_PAGES + 1)].map((_, idx) => (
            <Dot key={`dot-${idx}`} active={idx === page} onClick={() => gotoPage(idx)} />
          ))}
        </Pagination>
      )}
    </Wrap>
  );
};

const Set = styled.div`
  display: flex;
  flex-direction: row;
  &.hasMargin {
    margin-left: 3px;
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    margin-right: 2px;
  }
`;

const Wrap = styled.div`
  flex: 1;
  //background-color: red;
  display: flex;
  min-width: 0;
`;

const Container = styled.div`
  flex: 1;
  overflow: hidden;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    margin-bottom: 0;
  }
`;
const Slide = styled.div<Props>`
  display: flex;
  min-width: 0;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    transition: none;
    overflow-y: scroll;
    padding-bottom: 20px;
  }
`;
const Next = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  transform: translateX(50%) translateY(-50%);
  cursor: pointer;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: none;
  }
`;
const Previous = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  transform: translateX(-50%) translateY(-50%);
  cursor: pointer;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: none;
  }
`;
const ButtonPositioner = styled.div<{ isDoubleRow?: boolean }>`
  width: 1px;
  position: relative;
  //background: yellow;
`;

const Pagination = styled.div`
  position: absolute;
  bottom: -20px;
  right: 0px;
  display: flex;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: none;
  }
`;

export default CapabilityCarousel;
