import { ApiCapability } from 'components/Capabilities/types';
import { ApiItem } from 'components/Item/types';
import { ApiHeader, ApiLayouts, ApiTradeShow } from 'types/index';

const apiBase = 'https://experiencel3harris.com/api/';
const apiUrls = {
  getTiles: `${apiBase}tiles.php`,
  getCapabilities: `${apiBase}capabilities.php`,
  getLayouts: `${apiBase}layouts.php`,
  getHeaders: `${apiBase}headers.php`,
  getTradeShows: `${apiBase}tradeshows.php`,
};

const api = {
  getTiles: async (isStaging?: boolean) => {
    const response = await fetch(`${apiUrls.getTiles}${isStaging ? `?staging=1` : ``}`);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<ApiItem[]>;
  },
  getCapabilities: async (isStaging?: boolean) => {
    const response = await fetch(`${apiUrls.getCapabilities}${isStaging ? `?staging=1` : ``}`);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<ApiCapability[]>;
  },
  getLayouts: async (isStaging?: boolean) => {
    const response = await fetch(`${apiUrls.getLayouts}${isStaging ? `?staging=1` : ``}`);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<ApiLayouts>;
  },
  getHeaders: async (isStaging?: boolean) => {
    const response = await fetch(`${apiUrls.getHeaders}${isStaging ? `?staging=1` : ``}`);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<ApiHeader[]>;
  },
  getTradeshows: async (isStaging?: boolean) => {
    const response = await fetch(`${apiUrls.getTradeShows}${isStaging ? `?staging=1` : ``}`);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<ApiTradeShow[]>;
  },
};

export default api;
