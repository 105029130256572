import colors from 'constants/colors';
import mobile from 'constants/mobile';
import React from 'react';
import styled from 'styled-components';

type Props = {
  page: number;
  numberOfPages: number;
  hide?: boolean;
};

const PaginationSlider: React.FunctionComponent<Props> = ({ page, numberOfPages, hide }) => (
  <Track hide={hide}>
    <Bar page={page} numberOfPages={numberOfPages} />
  </Track>
);

const Track = styled.div<{ hide: Props['hide'] }>`
  height: 2px;
  width: 100%;
  background: ${colors.paginationGrey};
  margin-top: 15px;
  position: relative;
  ${({ hide }) =>
    !!hide &&
    `
    display: none;
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      display: block;
    }
  `}
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: none !important;
  }
`;

const Bar = styled.div<{ page: Props['page']; numberOfPages: Props['numberOfPages'] }>`
  ${({ page, numberOfPages }) => `
    background: ${colors.paginationGrey};
    height: 7px;
    width: ${(1 / numberOfPages) * 100}%;
    position: absolute;
    bottom: 0;
    left: ${((page - 1) / numberOfPages) * 100}%;
    transition: left 0.5s ease-in-out;
  `}
`;

export default PaginationSlider;
