import video from 'images/icon.video.svg';
import interactive from 'images/icon.interactive.svg';
import popup from 'images/icon.popup.svg';
import { ApiItemType, ItemType } from 'components/Item/types';
import { CapabilityTag, CapabilityType } from 'types';

export default {
  ITEM_SPACING: 3,
  MAX_SAFETY: 200,
};

export const ItemTypeImages = {
  [ApiItemType.INTERACTIVE]: interactive,
  [ApiItemType.POPUP]: popup,
  [ApiItemType.VIDEO]: video,
  //[ApiItemType.FOOTER_LINK]: popup,
};

export const tagFeaturedContent: { [key in CapabilityTag]: string[] } = {
  [CapabilityTag.SPACE_DOMAIN]: ['25', '39', '46', '54'],
  [CapabilityTag.AIR_DOMAIN]: ['26', '31', '119', '134'],
  [CapabilityTag.CYBER_DOMAIN]: ['11', '40', '41', '76'],
  [CapabilityTag.LAND_DOMAIN]: ['14', '64', '124', '156'],
  [CapabilityTag.MULTI_DOMAIN]: ['36', '43', '84', '157'],
  [CapabilityTag.SEA_DOMAIN]: ['22', '27', '108', '141'],
  [CapabilityTag.DEFENSE_INDUSTRY]: ['26', '44', '64', '67'],
  [CapabilityTag.CIVIL_INDUSTRY]: ['60', '66', '75', '172'],
  [CapabilityTag.COMMERCIAL_INDUSTRY]: ['9', '105', '109', '168'],
  [CapabilityTag.AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS]: ['10', '43', '73', '103'],
  [CapabilityTag.AUTONOMOUS_SYSTEMS_SOLUTIONS]: ['30', '127', '133', '141'],
  [CapabilityTag.COMMAND_AND_CONTROL_SOLUTIONS]: ['66', '98', '118', '136'],
  [CapabilityTag.ELECTRONIC_WARFARE_SOLUTIONS]: ['82', '16', '20', '116'],
  [CapabilityTag.INTEGRATED_MARITIME_SOLUTIONS]: ['22', '27', '74', '136'],
  [CapabilityTag.INTEGRATED_VISION_SOLUTIONS]: ['60', '73', '101', '117'],
  [CapabilityTag.ISR_AND_SIGINT_SOLUTIONS]: ['63', '78', '163', '170'],
  [CapabilityTag.MISSILE_WARNING_AND_DEFENSE_SOLUTIONS]: ['2', '39', '40', '46'],
  [CapabilityTag.PRECISION_MUNITIONS_SOLUTIONS]: ['42', '45', '97', '101'],
  [CapabilityTag.RESILIENT_COMMUNICATIONS_AND_NETWORKS_SOLUTIONS]: ['50', '56', '124', '126'],
  [CapabilityTag.SOFTWARE_AND_CYBER_SOLUTIONS]: ['41', '48', '76', '107'],
  [CapabilityTag.SPACE_SUPERIORITY_SOLUTIONS]: ['15', '71', '106', '130'],
  [CapabilityTag.TRAINING_AND_SIMULATION_SYSTEMS_SOLUTIONS]: ['98', '104', '109', '131'],
  [CapabilityTag.UNDERSEA_WARFARE_SOLUTIONS]: ['21', '23', '27', '127'],
  [CapabilityTag.PUBLIC_SAFETY_AND_CRITICAL_COMMUNICATIONS_SOLUTIONS]: ['61', '75', '105', '135'],

  [CapabilityTag.NOT_FOUND]: [],
  // WDS
  [CapabilityTag.WDS_C5ISR_SOLUTIONS]: ['56', '60', '64', '126'],
  [CapabilityTag.WDS_COMMERCIAL_AVIATION_SOLUTIONS]: ['12', '104', '144', '150'],
  [CapabilityTag.WDS_EO_IR_SENSOR_SOLUTIONS]: ['97', '86', '94', '168'],
  [CapabilityTag.WDS_EW_AND_CYBER_SOLUTIONS]: ['16', '20', '79', '82'],
  [CapabilityTag.WDS_ISR_AND_SIGINT_SOLUTIONS]: ['78', '81', '140', '149'],
  [CapabilityTag.WDS_MARITIME_AND_AUTONOMOUS_SOLUTIONS]: ['21', '28', '120', '133'],
  [CapabilityTag.WDS_TAG_NEEDED]: [],
  // SS
  [CapabilityTag.SS_ANTENNAS]: [],
  [CapabilityTag.SS_MISSLE_WARNING_DEFENSE]: [],
  [CapabilityTag.SS_POSITIONING_NAVIGATION_TIMING]: [],
  [CapabilityTag.SS_SPACE_SUPERIORITY]: [],
  [CapabilityTag.SS_TELESCOPES_EXPLORATION]: [],
  [CapabilityTag.SS_WEATHER]: [],
  [CapabilityTag.SS_TAG_NEEDED]: [],
  // NL
  [CapabilityTag.NL_AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS]: [],
  [CapabilityTag.NL_DISTRIBUTED_MARITIME_OPERATIONS]: [],
  [CapabilityTag.NL_ELECTRONIC_WARFARE_SOLUTIONS]: [],
  [CapabilityTag.NL_ISR_AND_SIGINT_SOLUTIONS]: [],
  [CapabilityTag.NL_MARITIME_AND_AUTONOMOUS_SYSTEMS]: [],
  [CapabilityTag.NL_RESILIENT_COMMUNICATIONS_AND_NETWORKS]: [],
  [CapabilityTag.NL_TAG_NEEDED]: [],
};

export const tagHeroHeaderId: { [key in CapabilityTag]: string } = {
  [CapabilityTag.SPACE_DOMAIN]: '241',
  [CapabilityTag.AIR_DOMAIN]: '242',
  [CapabilityTag.LAND_DOMAIN]: '243',
  [CapabilityTag.SEA_DOMAIN]: '244',
  [CapabilityTag.CYBER_DOMAIN]: '245',
  [CapabilityTag.MULTI_DOMAIN]: '246',
  [CapabilityTag.DEFENSE_INDUSTRY]: '247',
  [CapabilityTag.COMMERCIAL_INDUSTRY]: '248',
  [CapabilityTag.CIVIL_INDUSTRY]: '249',
  [CapabilityTag.AUTONOMOUS_SYSTEMS_SOLUTIONS]: '250',
  [CapabilityTag.AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS]: '251',
  [CapabilityTag.COMMAND_AND_CONTROL_SOLUTIONS]: '252',
  [CapabilityTag.ELECTRONIC_WARFARE_SOLUTIONS]: '253',
  [CapabilityTag.INTEGRATED_MARITIME_SOLUTIONS]: '254',
  [CapabilityTag.INTEGRATED_VISION_SOLUTIONS]: '255',
  [CapabilityTag.ISR_AND_SIGINT_SOLUTIONS]: '256',
  [CapabilityTag.MISSILE_WARNING_AND_DEFENSE_SOLUTIONS]: '257',
  [CapabilityTag.PUBLIC_SAFETY_AND_CRITICAL_COMMUNICATIONS_SOLUTIONS]: '259',
  [CapabilityTag.PRECISION_MUNITIONS_SOLUTIONS]: '258',
  [CapabilityTag.RESILIENT_COMMUNICATIONS_AND_NETWORKS_SOLUTIONS]: '260',
  [CapabilityTag.SOFTWARE_AND_CYBER_SOLUTIONS]: '261',
  [CapabilityTag.SPACE_SUPERIORITY_SOLUTIONS]: '262',
  [CapabilityTag.TRAINING_AND_SIMULATION_SYSTEMS_SOLUTIONS]: '263',
  [CapabilityTag.UNDERSEA_WARFARE_SOLUTIONS]: '264',
  //[CapabilityTag.C5ISR_SOLUTIONS]: '265',
  //[CapabilityTag.COMMERCIAL_AVIATION_SOLUTIONS]: '266',
  [CapabilityTag.NOT_FOUND]: '',
  // WDS
  [CapabilityTag.WDS_C5ISR_SOLUTIONS]: '265',
  [CapabilityTag.WDS_COMMERCIAL_AVIATION_SOLUTIONS]: '266',
  [CapabilityTag.WDS_EO_IR_SENSOR_SOLUTIONS]: '267',
  [CapabilityTag.WDS_EW_AND_CYBER_SOLUTIONS]: '268',
  [CapabilityTag.WDS_ISR_AND_SIGINT_SOLUTIONS]: '269',
  [CapabilityTag.WDS_MARITIME_AND_AUTONOMOUS_SOLUTIONS]: '270',
  [CapabilityTag.WDS_TAG_NEEDED]: '',
  // SS
  [CapabilityTag.SS_ANTENNAS]: '',
  [CapabilityTag.SS_MISSLE_WARNING_DEFENSE]: '',
  [CapabilityTag.SS_POSITIONING_NAVIGATION_TIMING]: '',
  [CapabilityTag.SS_SPACE_SUPERIORITY]: '',
  [CapabilityTag.SS_TELESCOPES_EXPLORATION]: '',
  [CapabilityTag.SS_WEATHER]: '',
  [CapabilityTag.SS_TAG_NEEDED]: '',
  // NL
  [CapabilityTag.NL_AIRCRAFT_MISSIONIZATION_AND_AVIONICS_SOLUTIONS]: '278',
  [CapabilityTag.NL_DISTRIBUTED_MARITIME_OPERATIONS]: '280',
  [CapabilityTag.NL_ELECTRONIC_WARFARE_SOLUTIONS]: '279',
  [CapabilityTag.NL_ISR_AND_SIGINT_SOLUTIONS]: '',
  [CapabilityTag.NL_MARITIME_AND_AUTONOMOUS_SYSTEMS]: '',
  [CapabilityTag.NL_RESILIENT_COMMUNICATIONS_AND_NETWORKS]: '',
  [CapabilityTag.NL_TAG_NEEDED]: '',
};
