import { ApiCapability } from 'components/Capabilities/types';
import { ApiItem } from 'components/Item/types';
import { ApiHeader, ApiLayouts, ApiTradeShow } from 'types/index';
import { createAsyncAction } from 'typesafe-actions';

export const getTiles = createAsyncAction('GET_TILES_REQUEST', 'GET_TILES_SUCCESS', 'GET_TILES_FAILURE')<
  { isStaging?: boolean },
  ApiItem[],
  undefined
>();
export const getCapabilities = createAsyncAction('GET_CAPABILITIES_REQUEST', 'GET_CAPABILITIES_SUCCESS', 'GET_CAPABILITIES_FAILURE')<
  { isStaging?: boolean },
  ApiCapability[],
  undefined
>();
export const getLayouts = createAsyncAction('GET_LAYOUTS_REQUEST', 'GET_LAYOUTS_SUCCESS', 'GET_LAYOUTS_FAILURE')<
  { isStaging?: boolean },
  ApiLayouts,
  undefined
>();
export const getHeaders = createAsyncAction('GET_HEADERS_REQUEST', 'GET_HEADERS_SUCCESS', 'GET_HEADERS_FAILURE')<
  { isStaging?: boolean },
  ApiHeader[],
  undefined
>();
export const getTradeShows = createAsyncAction('GET_TRADESHOWS_REQUEST', 'GET_TRADESHOWS_SUCCESS', 'GET_TRADESHOWS_FAILURE')<
  { isStaging?: boolean },
  ApiTradeShow[],
  undefined
>();
