import { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  id: string;
};

export const PortalComp: React.FunctionComponent<Props> = ({ id, children }) => {
  const el = useRef(document.getElementById(id) || document.createElement('div'));
  const [dynamic] = useState(!el.current.parentElement);
  useEffect(() => {
    if (dynamic) {
      el.current.id = id;
      document.body.appendChild(el.current);
    }
    return () => {
      if (dynamic && el.current.parentElement) {
        el.current.parentElement.removeChild(el.current);
      }
    };
  }, [id]);
  return createPortal(children, el.current);
};

export const Portal = memo<React.FunctionComponent<Props>>(PortalComp);

// export const Portal: React.FunctionComponent<Props> = ({ children }) => {
//   const [container] = useState(() => {
//     const el = document.createElement('div');
//     el.classList.add('overlsyssas');
//     return el;
//   });

//   useEffect(() => {
//     document.body.appendChild(container);
//     return () => {
//       document.body.removeChild(container);
//     };
//   }, []);

//   return createPortal(children, container);
// };
