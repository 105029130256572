import { Portal } from 'components/Portal';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import colors from 'constants/colors';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import CloseButton from 'components/CloseButton';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';

type Props = {
  onClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
  hideIcons?: boolean;
  closeWidth?: number;
  backgroundOpacity?: number;
};

const Modal: React.FunctionComponent<Props> = ({ onClose, hideIcons, closeWidth, backgroundOpacity, children }) => {
  const [running, setRunning] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { isMobile } = useIsDevice();

  const run = () => {
    setRunning(true);
    if (ref.current) {
      disableBodyScroll(ref.current);
    }
    // disableScroll.on();
  };

  const openContent = () => {
    setShowContent(true);
  };

  useEffect(() => {
    const to = setTimeout(() => run(), 100);
    if (ref.current) {
      // if (!isMobile) {
      //   disableBodyScroll(ref.current);
      // }
    }
    return () => {
      clearTimeout(to);
      clearAllBodyScrollLocks();
      //disableScroll.off();
    };
  }, []);

  useEffect(() => {
    if (running) {
      const to = setTimeout(() => openContent(), 300);
      return () => clearTimeout(to);
    }
  }, [running]);

  const opacity = !!backgroundOpacity ? backgroundOpacity : 0.8;

  return (
    <Portal id="overlays">
      <Container className={classNames('close-viewall', { running: running })} ref={ref} opacity={opacity} onClick={onClose}>
        <ContentContainer visible={showContent} className="close-viewall">
          <ButtonWrap>
            <CloseButton onClick={onClose} width={closeWidth} className="close-viewall" />
          </ButtonWrap>
          {children}
        </ContentContainer>
      </Container>
    </Portal>
  );
};

const Container = styled.div<{ running?: boolean; opacity: number }>`
display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: fixed;
  background-color: ${({ opacity }) => colors.customFade.darkGreyGreen(opacity)};
  backdrop-filter: blur(3px);
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;

&.running {
  transition: all 0.4s ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 20;

  .content {
    opacity: 0;
    transition: opacity 0.7s ease-in-out;
  } 
`;

const ContentContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 32px 56px;
  opacity: ${({ visible }) => (!!visible ? 1 : 0)};
  transition: opacity 0.35s ease-in-out;
`;

const ButtonWrap = styled.div`
  position: absolute;
  top: 32px;
  right: 0;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      top: auto;
      bottom: 100px;
      z-index: 99;
    }
  }
`;

export default Modal;
