import { ApiItem, ApiItemType } from 'components/Item/types';
import { Portal } from 'components/Portal';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import colors from 'constants/colors';
import disableScroll from 'disable-scroll';

import { ItemImage, Grad, TextWrap, Icon, Text } from 'components/Item';
import { ItemTypeImages } from 'constants/index';
import { InteractiveContent, PopupContent, VideoContent } from 'components/ExpandedContent';
import CloseButton from 'components/CloseButton';
import { typeIconSize } from 'components/SubHeadingIcon';
import mobile from 'constants/mobile';
import ShareButton from 'components/ShareButton';
import useIsDevice from 'hooks/useIsDevice';

type Props = {
  item: ApiItem;
  onClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
  startingPosition: DOMRect;
  hideIcons?: boolean;
  hideContentExpand?: boolean;
  itemImg?: string;
};

const ItemExpanded: React.FunctionComponent<Props> = (props) => {
  const { item, onClose, startingPosition, hideIcons, hideContentExpand } = props;
  const [running, setRunning] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const { isMobile } = useIsDevice();

  const position: Position = {
    top: startingPosition.top, // + window.scrollY,
    left: startingPosition.left,
    width: startingPosition.width,
    height: startingPosition.height,
  };

  const run = () => {
    setRunning(true);
    disableScroll.on();
  };

  const openContent = () => {
    setShowContent(true);
  };

  useEffect(() => {
    const to = setTimeout(() => run(), 50);
    return () => {
      clearTimeout(to);
      disableScroll.off();
    };
  }, []);

  useEffect(() => {
    if (running) {
      const to = setTimeout(() => openContent(), 400);
      return () => clearTimeout(to);
    }
  }, [running]);

  return (
    <Portal id="items">
      <Container {...position} className={classNames({ running: running })} ref={ref}>
        {!hideContentExpand && (
          <Content className="content">
            <ItemImage src={props.itemImg} style={{ height: '100%' }} className="expanded" />
            <Grad className="grad" />
            <TextWrap>
              {!hideIcons && (
                <Icon src={ItemTypeImages[item.type]} width={typeIconSize[item.type].width} height={typeIconSize[item.type].height} />
              )}
              <Text>{item.label}</Text>
            </TextWrap>
          </Content>
        )}

        <ContentContainer type={item.type} onClick={onClose} className="close-item">
          <ButtonWrap isActive={showContent && item.type !== ApiItemType.POPUP}>
            <CloseButton onClick={onClose} className="close-item" />
          </ButtonWrap>

          <ShareWrap isActive={showContent && item.type !== ApiItemType.POPUP}>
            <ShareButton title={item.label || 'Experience L3Harris'} url={item.link} />
          </ShareWrap>

          {showContent && item.type === ApiItemType.INTERACTIVE && <InteractiveContent url={item.link} />}
          {showContent && item.type === ApiItemType.VIDEO && <VideoContent url={item.link} />}
          {showContent && item.type === ApiItemType.POPUP && (
            <PopupContent
              title={item.additionalContentTitle}
              text={item.additionalContent}
              url={item.link}
              onClose={onClose}
              img={isMobile ? item.additionalImgMobile : item.additionalImgLarge}
              isMobile={isMobile}
            />
          )}
        </ContentContainer>
      </Container>
    </Portal>
  );
};

type Position = {
  top: number;
  left: number;
  width: number;
  height: number;
};

const Container = styled.div<Position & { running?: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  position: fixed;
  background-color: ${colors.customFade.darkGreyGreen(0.8)};
  backdrop-filter: blur(3px);
  ${({ top, left, width, height }) => `
    top: ${top}px;
    left: ${left}px;
    width: ${width}px;
    height: ${height}px;
  `}
  &.running {
    transition: all 0.4s ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 20;

    .content {
      opacity: 0;
      transition: opacity 0.7s ease-in-out;
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const ContentContainer = styled.div<{ type: ApiItemType }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 32px 56px;
  ${({ type }) =>
    type === ApiItemType.POPUP &&
    `
  display: flex;
  align-items: center;
  justify-content: center;
  `}
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    padding: 0;
  }
`;

const ButtonWrap = styled.div<{ isActive?: boolean }>`
  position: absolute;
  top: 32px;
  right: 0;
  opacity: ${({ isActive }) => (!!isActive ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    top: auto;
    bottom: 100px;
    z-index: 99;
  }
`;

const ShareWrap = styled(ButtonWrap)`
  top: 132px;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    top: auto;
    bottom: 160px;
    z-index: 99;
  }
`;

export default ItemExpanded;
