import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ScaledSubHeading, SubHeading } from 'components/Type';
import Carousel from 'components/Carousel';
import { ApiItem, ApiItemType, Item, ItemSize, ItemType } from 'components/Item/types';
import SubHeadingIcon from 'components/SubHeadingIcon';
import ViewAllButton from 'components/ViewAllButton';
import useIsDevice from 'hooks/useIsDevice';

type Props = {
  items: ApiItem[];
  type: ApiItemType;
  debug?: boolean;
};

const ItemCarouselByType: React.FunctionComponent<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useLayoutEffect(() => {
    const measure = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  });

  const getHeading = () => {
    switch (props.type) {
      case ApiItemType.INTERACTIVE:
        return 'Interactive Experiences (A-Z)';
      case ApiItemType.POPUP:
        return 'Additional Capabilities (A-Z)';
      case ApiItemType.VIDEO:
        return 'Videos (A-Z)';
      //case ApiItemType.FOOTER_LINK:
      //  return '';
    }
  };

  const { isMobile, isTablet } = useIsDevice();

  const numItemsDisplay =
    props.type === ApiItemType.POPUP ? (isMobile ? (isTablet ? 3.5 : 2.5) : 9) : isMobile ? (isTablet ? 2.5 : 1.5) : 5;
  const hasViewAll = props.items.length > numItemsDisplay;
  const heading = getHeading();

  return (
    <Container ref={ref}>
      <ScaledSubHeading marginTop={6}>
        {heading}
        <SubHeadingIcon type={props.type} />
        {hasViewAll && <ViewAllButton items={props.items} title={heading} type={props.type} hideIcons />}
      </ScaledSubHeading>

      <Carousel
        size={ItemSize.SMALL}
        width={width}
        items={props.items}
        numItemsDisplay={numItemsDisplay}
        hideIcons
        debug={props.debug}
        name={`${props.type}`}
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  margin-top: 10px;
  position: relative;
  min-width: 0;
`;

export default ItemCarouselByType;
