import { ApiCapability } from 'components/Capabilities/types';
import { sortBy } from 'lodash';
import { ApiHeader } from 'types/index';
import { MainState } from './reducers';

export const selectCoreCapabilities = (state: MainState) => state.capabilities.filter((c) => c.parentTagId === 0);

export const selectCoreCapabilityByUrlTitle = (state: MainState, urlTitle: string) =>
  state.capabilities.find((c) => c.urlTitle === urlTitle);

export const selectCapabilitiesById = (state: MainState, ids: number[]) => state.capabilities.filter((c) => ids.includes(c.id));

export const selectCapabilitiesByIdPreserveOrder = (state: MainState, ids: number[]) => {
  const capabilities: ApiCapability[] = [];
  ids.map((id) => {
    const capability = state.capabilities?.find((c) => c.id === id);
    !!capability && capabilities.push(capability);
  });
  return capabilities;
};

export const selectCapabilityChildren = (state: MainState, tagId: number) =>
  state.capabilities.filter((c) => c.parentTagId === tagId && c.tradeShowTagId === -1);

export const selectTradeshowCapabilities = (state: MainState, tagId: number) =>
  state.capabilities.filter((c) => c.tradeShowTagId === tagId);

export const selectTilesByTag = (state: MainState, tag: number) =>
  sortBy(
    state.tiles.filter((t) => t.tags.includes(tag)),
    (item) => item.label,
  );
export const selectTilesByIds = (state: MainState, ids: number[]) =>
  state.tiles.filter((t) => ids.includes(t.id)).sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

export const selectTileById = (state: MainState, id: number) => state.tiles.find((t) => t.id === id);

export const selectTiles = (state: MainState) => state.tiles;

export const selectLayouts = (state: MainState) => state.layouts;

export const selectHomeLayout = (state: MainState) => selectLayouts(state)?.home;

export const selectHeaders = (state: MainState) => state.headers;

export const selectHeadersByIds = (state: MainState, ids: number[]) => {
  //state.headers?.filter((t) => ids.includes(t.id)).sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id)) || [];
  const headers: ApiHeader[] = [];
  ids.map((id) => {
    const header = state.headers?.find((t) => t.id === id);
    !!header && headers.push(header);
  });
  return headers;
};

export const selectTradeShows = (state: MainState) => state.tradeShows;

export const selectTradeShowByTagId = (state: MainState, tagId: number) => state.tradeShows?.find((t) => t.tagId === tagId);
