import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ScaledSubHeading, SubHeading } from 'components/Type';
import Carousel from 'components/Carousel';
import { ApiItem, ApiItemType, Item, ItemSize, ItemType } from 'components/Item/types';
import SubHeadingIcon from 'components/SubHeadingIcon';
import ViewAllButton from 'components/ViewAllButton';
import useIsDevice from 'hooks/useIsDevice';

type Props = {
  items: ApiItem[];
};

const FeaturedInteractive: React.FunctionComponent<Props> = ({ items }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  //const firstItem = props.items[0];
  //const items = props.items.map((item) => (item.id === firstItem.id ? { ...firstItem, size: ItemSize.MEDIUM } : item));

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useLayoutEffect(() => {
    const measure = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  });

  const hasViewAll = items.length > 7;
  const heading = 'Featured Interactive Experiences';

  const { isMobile, isTablet } = useIsDevice();
  const numItemsDisplay = isMobile ? (isTablet ? 2.5 : 1.5) : 5;

  return (
    <Container ref={ref}>
      <ScaledSubHeading marginTop={6}>
        {heading}
        <SubHeadingIcon type={ApiItemType.INTERACTIVE} />
        {hasViewAll && <ViewAllButton items={items} title={heading} type={ApiItemType.INTERACTIVE} hideIcons />}
      </ScaledSubHeading>

      <Carousel
        size={ItemSize.MEDIUM}
        width={width}
        items={items}
        hideIcons
        name="featured-interactive"
        numItemsDisplay={numItemsDisplay}
        hasFeaturedItem
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  margin-top: 10px;
  position: relative;
  min-width: 0;
`;

export default FeaturedInteractive;
