import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import loadingAnimation from 'images/animated.loader.oval.svg';
import classNames from 'classnames';

const MIN_DISPLAY = 200; // 250;

type Props = {
  src: string;
  style?: React.CSSProperties;
  duration?: number;
};

const ImageLoader: React.FunctionComponent<Props> = (props) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const styles = { ...props.style };
  const duration = props.duration || 0.35;

  useEffect(() => {
    const to = setTimeout(() => setShowContent(true), MIN_DISPLAY);
    return () => clearTimeout(to);
  }, [hasLoaded]);

  const img = new Image();
  img.src = props.src;
  img.onload = () => {
    setHasLoaded(true);
  };

  return (
    <Container style={styles}>
      <FadeIn className={classNames({ active: showContent })} duration={duration}>
        {hasLoaded && props.children}
      </FadeIn>
      <LoadingIconWrap className={classNames({ active: !hasLoaded })}>
        <img src={loadingAnimation} width={50} height={50} />
      </LoadingIconWrap>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const LoadingIconWrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  &.active {
    opacity: 1;
  }
  transition: opacity 0.35s ease-in-out;
`;

const FadeIn = styled.div<{ active?: boolean; duration: number }>`
  opacity: 0;
  &.active {
    opacity: 1;
  }
  transition: opacity ${({ duration }) => duration}s ease-in-out;
`;

export default ImageLoader;
