import colors from 'constants/colors';
import React from 'react';
import styled from 'styled-components';

import close from 'images/icon.close.svg';

type Props = {
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  width?: number;
  className?: string;
};

const CloseButton: React.FunctionComponent<Props> = (props) => (
  <Container className={props.className} onClick={props.onClick} width={props.width}>
    <img src={close} width={25} height={25} />
  </Container>
);
const Container = styled.div<{ width?: number }>`
  background-color: ${colors.red};
  width: ${({ width }) => (!!width ? width : 56)}px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
    pointer-events: none;
  }
`;

export default CloseButton;
