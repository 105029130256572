import { ApiCapability } from 'components/Capabilities/types';
import { ApiItem } from 'components/Item/types';
import { ApiHeader, ApiLayouts, ApiTradeShow } from 'types/index';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';

export type Action = ActionType<typeof actions>;

export type MainState = {
  tiles: ApiItem[];
  capabilities: ApiCapability[];
  layouts?: ApiLayouts;
  headers?: ApiHeader[];
  tradeShows?: ApiTradeShow[];
};

const initialState: MainState = {
  tiles: [],
  capabilities: [],
  headers: [],
  tradeShows: [],
};

export const mainReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case getType(actions.getTiles.success):
      return {
        ...state,
        tiles: action.payload,
      };
    case getType(actions.getCapabilities.success):
      return {
        ...state,
        capabilities: action.payload,
      };
    case getType(actions.getLayouts.success):
      return {
        ...state,
        layouts: action.payload,
      };
    case getType(actions.getHeaders.success):
      return {
        ...state,
        headers: action.payload,
      };
    case getType(actions.getTradeShows.success):
      return {
        ...state,
        tradeShows: action.payload,
      };
    default:
      return initialState;
  }
};
