import colors from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import chevron from 'images/icon.chevron-small.svg';

type Props = {
  onClick: () => void;
  label: string;
  fontSize?: number;
};

const ChevronButton: React.FunctionComponent<Props> = ({ onClick, label, fontSize }) => (
  <Button onClick={onClick} style={!!fontSize ? { fontSize } : {}}>
    <Chevron className="chevron" src={chevron} height={'100%'} />
    {label}
  </Button>
);

const Button = styled.div`
  color: ${colors.white};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  &:hover {
    opacity: 0.8;
    .chevron {
      margin-right: 6px;
    }
  }
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
`;

const Chevron = styled.img`
  margin-right: 4px;
  transition: margin 0.2s ease-in-out;
`;

export default ChevronButton;
