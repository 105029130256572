import colors from 'constants/colors';
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import share from 'images/icon.share.svg';
import { copyToClipboard } from 'helpers/index';

const PROMPT_DURATION = 4000;

type Props = {
  title: string;
  url: string;
  width?: number;
};

const ShareButton: React.FunctionComponent<Props> = ({ title, url, width }) => {
  const [hasClicked, setHasClicked] = useState(false);

  useEffect(() => {
    if (hasClicked) {
      const to = setTimeout(() => setHasClicked(false), PROMPT_DURATION);
      return () => clearTimeout(to);
    }
  }, [hasClicked]);

  const onShare = () => {
    if (!!navigator.share) {
      navigator.share({ title, url });
    } else {
      copyToClipboard(url);
      setHasClicked(true);
    }
  };
  return (
    <Container onClick={onShare} width={width}>
      <img src={share} width={33} height={29} />
      {hasClicked && <Prompt>SHARE LINK COPIED TO CLIPBOARD</Prompt>}
    </Container>
  );
};
const Container = styled.div<{ width?: number }>`
  background-color: ${colors.red};
  width: ${({ width }) => (!!width ? width : 56)}px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  img {
    margin-bottom: 3px;
  }
`;

const fadeOut = keyframes`
  0% { opacity: 1 }
  20% { opacity: 1 }
  100% { opacity: 0 }
`;

const Prompt = styled.div`
  position: absolute;
  right: 56px;
  top: 0;
  height: 50px;
  background-color: ${colors.red};
  z-index: 10;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  font-weight: 600;
  animation: ${fadeOut} ${PROMPT_DURATION}ms forwards;
`;

export default ShareButton;
