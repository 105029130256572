import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ScaledSubHeading, SubHeading } from 'components/Type';
import Carousel from 'components/Carousel';
import { ApiItem, ItemSize } from 'components/Item/types';
import ViewAllButton from 'components/ViewAllButton';
import useIsDevice from 'hooks/useIsDevice';

type Props = {
  items: ApiItem[];
  title: string;
  debug?: boolean;
  hideIcons?: boolean;
};

const ItemCarousel: React.FunctionComponent<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useLayoutEffect(() => {
    const measure = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  });

  const { isMobile, isTablet } = useIsDevice();

  const numItemsDisplay = isMobile ? (isTablet ? 2.5 : 1.5) : 5;
  const hasViewAll = props.items.length > numItemsDisplay;

  return (
    <Container ref={ref}>
      <ScaledSubHeading marginTop={6}>
        {props.title}
        {hasViewAll && <ViewAllButton items={props.items} title={props.title} />}
      </ScaledSubHeading>

      <Carousel
        size={ItemSize.SMALL}
        width={width}
        items={props.items}
        numItemsDisplay={numItemsDisplay}
        hideIcons={props.hideIcons}
        debug={props.debug}
        name={`${props.title}`}
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  margin-top: 10px;
  position: relative;
  min-width: 0;
`;

export default ItemCarousel;
