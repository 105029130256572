import React from 'react';
import { FlexHorizontal } from 'components/Common';
import Capabilities from 'components/Capabilities';
import { useLocation, useParams } from 'react-router-dom';
import { Content } from 'App';

import PageHeaderStatic from '../../components/PageHeaderStatic';
import PageFooter from 'components/PageFooter';
import Page from 'components/Page';
import useWindowSize from 'hooks/useWindowSize';
import mobile from 'constants/mobile';
import useIsDevice from 'hooks/useIsDevice';
import { ApiCapability } from 'components/Capabilities/types';
import { useSelector } from 'react-redux';
import {
  selectCapabilityChildren,
  selectCoreCapabilityByUrlTitle,
  selectTradeshowCapabilities,
  selectTradeShowByTagId,
} from 'state/selectors';
import { MainState } from 'state/reducers';

const TradeShowPage: React.FunctionComponent = () => {
  const windowWidth = useWindowSize().width;
  const { isMobile, isTablet } = useIsDevice();
  const { tradeShowId, tradeShowCapabilityId } = useParams();

  const { pathname } = useLocation();

  const coreCapability = useSelector((state: MainState) => selectCoreCapabilityByUrlTitle(state, tradeShowId || ''));
  const capabilities = useSelector((state: MainState) => selectTradeshowCapabilities(state, coreCapability?.tagId || -1));
  const skin = useSelector((state: MainState) => selectTradeShowByTagId(state, coreCapability?.tagId || -1));

  if (!tradeShowId) return null;

  const img = skin?.img || '';
  const imgLarge = skin?.imgLarge || '';
  const imgMobile = skin?.imgMobile || '';
  const imgTablet = skin?.imgTablet || '';
  const footerLink = skin?.footerLink || '';
  const footerLinkText = 'Visit L3Harris.com Event Page';

  return (
    <Page key={pathname}>
      <PageHeaderStatic
        img={isMobile ? (isTablet ? imgTablet : imgMobile) : img}
        imgLarge={isMobile ? (isTablet ? imgTablet : imgMobile) : imgLarge}
        noGrad
        minWidth={1920}
        noPreloader
        noShadow={isMobile}
      />
      <Content>
        <FlexHorizontal style={{ marginTop: 50 }}>
          <Capabilities capabilityId={tradeShowId} hideHeading isTradeShow hideInfo subHeading="" items={capabilities} />
        </FlexHorizontal>
      </Content>
      <PageFooter footerLink={footerLink} footerLinkText={footerLinkText} />
    </Page>
  );
};

export default TradeShowPage;
