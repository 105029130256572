import { ApiCapability, Capability } from 'components/Capabilities/types';
import { ScaledLabel } from 'components/Type';
import constants from 'constants/index';
import colors from 'constants/colors';
import React, { useState, MouseEvent, useRef, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import icon from 'images/icon.info.svg';
import classNames from 'classnames';
import mobile from 'constants/mobile';
import tablet from 'constants/tablet';
import { useSelector } from 'react-redux';
import { selectTradeShowByTagId } from 'state/selectors';
import { MainState } from 'state/reducers';

type StyleProps = {
  $margin?: boolean;
  hideInfo?: boolean;
  useLargeImage?: boolean;
  isShowingInfo?: boolean;
  forceWidth?: number;
};

const CapabilityItem: React.FunctionComponent<ApiCapability & StyleProps> = (props) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [shouldShowOverlay, setShouldShowOverlay] = useState(false);
  const [width, setWidth] = useState(0);
  const tradeShowData = useSelector((state: MainState) => selectTradeShowByTagId(state, props.tagId));
  const { id, label, img, imgLarge, $margin, useLargeImage, hideInfo, isTradeShow, forceWidth } = props;

  const shouldHideInfo = props.infoTitle === undefined ? hideInfo : !props.infoTitle;

  const onClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!!target && target.classList.contains('info')) {
      return;
    }

    if (isTradeShow) {
      if (!tradeShowData?.displayCapabilities) {
        navigate(`${props.url}/all`);
        return;
      }
    }

    navigate(props.url);
  };
  const onInfoClick = (e: MouseEvent) => {
    setShouldShowOverlay(!shouldShowOverlay);
  };

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useLayoutEffect(() => {
    const measure = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  });

  return (
    <CapabilityContainer
      onClick={onClick}
      $margin={$margin}
      label={label}
      isShowingInfo={shouldShowOverlay}
      ref={ref}
      className={classNames({ hasMargin: $margin, isShowingInfo: shouldShowOverlay, isDummy: label === 'DUMMY' })}
      style={forceWidth ? { width: `${forceWidth}px` } : {}}
    >
      {!shouldHideInfo && (
        <InfoOverlay className={classNames({ isEnabled: shouldShowOverlay })}>
          <InfoOverlayTitle>{props.infoTitle}</InfoOverlayTitle>
          <InfoOverlayText>{props.infoSubTitle}</InfoOverlayText>
        </InfoOverlay>
      )}

      <CapabilityImage src={useLargeImage ? imgLarge : img} />

      {!shouldHideInfo && <Icon src={icon} onClick={onInfoClick} className="info" />}
      <LabelContainer className="label">
        <ScaledLabel content={label} />
      </LabelContainer>
    </CapabilityContainer>
  );
};

const InfoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(25, 28, 28, 0.6) 0%, rgba(25, 28, 28, 0.98) 100%);
  padding: 70px 25px 0;
  opacity: 0;
  &.isEnabled {
    opacity: 1;
  }
  transition: opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const InfoOverlayTitle = styled.h5`
  font-weight: 600;
  //font-size: 20px;
  font-size: 1.3vw;
  //line-height: 26px;
  color: ${colors.white};
  margin-bottom: 10px;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    font-size: 3vw;
    font-size: clamp(15px, 3vw, 25px);
  }
`;

const InfoOverlayText = styled.h5`
  font-weight: 400;
  //font-size: 20px;
  font-size: 1.2vw;
  //line-height: 26px;
  color: ${colors.white};
  text-align: center;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    font-size: 15px;
    font-size: 3vw;
    font-size: clamp(15px, 3vw, 25px);
  }
`;

const CapabilityImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
`;

const CapabilityContainer = styled.div<Partial<Capability> & StyleProps>`
  background-color: ${colors.black};
  flex: 1;
  &.hasMargin {
    margin-left: ${constants.ITEM_SPACING}px;
  }
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  cursor: pointer;
  border-radius: 3px;
  overflow: hidden;
  transition: flex 0.4s ease-in-out;
  &:hover .label {
    background-color: ${colors.fadedEnabled.black};
    padding-bottom: 18px;
  }
  &.isShowingInfo:hover .label {
    padding-bottom: 40px;
  }
  &.isDummy {
    opacity: 0;
    pointer-events: none;
  }
  .loneItem & {
    width: 100% !important;
  }
  @media (min-width: ${tablet.MIN_WIDTH}px) and (max-width: ${tablet.MAX_WIDTH}px) {
    // width: 33%;
    // display: inline-block;
    flex: none;
  }
  @media (max-width: ${tablet.MIN_WIDTH}px) {
    // width: 49%;
    // display: inline-block;
    flex: none;
  }
`;
const LabelContainer = styled.div`
  padding: 18px 23px;
  background-color: ${colors.faded.black};
  display: flex;
  flex: 1;
  transition: background-color 0.4s ease-in-out, padding 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const Icon = styled.img`
  width: 45px;
  height: 45px;
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
`;

export default CapabilityItem;
