import React from 'react';
import { FlexHorizontal } from 'components/Common';
import Capabilities from 'components/Capabilities';
import { useLocation, useParams } from 'react-router-dom';
import { Content } from 'App';

import PageHeaderStatic from '../../components/PageHeaderStatic';
import PageFooter from 'components/PageFooter';
import Page from 'components/Page';
import { useSelector } from 'react-redux';
import { MainState } from 'state/reducers';
import { selectCapabilityChildren, selectCoreCapabilityByUrlTitle } from 'state/selectors';

const CapabilityPage: React.FunctionComponent = () => {
  const { capabilityId } = useParams();
  const { pathname } = useLocation();

  const coreCapability = useSelector((state: MainState) => selectCoreCapabilityByUrlTitle(state, capabilityId || ''));
  const capabilities = useSelector((state: MainState) => selectCapabilityChildren(state, coreCapability?.tagId || -1));

  if (!capabilityId) return null;

  const isShowingTradeShows = capabilityId === 'trade-shows';

  const img = `/images/assets/00_Slimline_Header_S.jpg`;

  const footerLink = isShowingTradeShows ? 'https://www.l3harris.com/events' : undefined;
  const footerLinkText = isShowingTradeShows ? 'Visit L3Harris.com Events Page' : undefined;

  return (
    <Page key={pathname}>
      <PageHeaderStatic img={img} imgLarge={img} noGrad minWidth={1920} noPreloader noMobile />
      <Content>
        <FlexHorizontal style={{ marginTop: 30 }}>
          <Capabilities
            capabilityId={capabilityId}
            hideInfo
            hideHeading={isShowingTradeShows}
            items={capabilities}
            subHeading={coreCapability?.label || ''}
          />
        </FlexHorizontal>
      </Content>
      <PageFooter footerLink={footerLink} footerLinkText={footerLinkText} />
    </Page>
  );
};

export default CapabilityPage;
