import classNames from 'classnames';
import CloseButton from 'components/CloseButton';
import ImageLoader from 'components/ImageLoader';
//import { HeadingVariant } from 'components/PageHeaderCopyBlock';
import colors from 'constants/colors';
import mobile from 'constants/mobile';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import loadingAnimation from 'images/animated.loader.oval.svg';

type InteractiveContentProps = {
  url: string;
};

export const InteractiveContent: React.FunctionComponent<InteractiveContentProps> = ({ url }) => {
  const [shouldFade, setShouldFade] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const to = setTimeout(() => setShouldFade(true), 250);
    return () => clearTimeout(to);
  }, []);
  return (
    <IFrameWrap className={classNames({ isActive: shouldFade })}>
      <img className={classNames('loader', { complete: isLoaded })} src={loadingAnimation} width={50} height={50} />
      <IFrame src={url} isActive={isLoaded} onLoad={() => setIsLoaded(true)} />
    </IFrameWrap>
  );
};

const IFrameWrap = styled.div<{ isActive?: boolean }>`
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    &.complete {
      opacity: 0;
    }
  }
  &.isActive {
    opacity: 1;
  }
`;

type VideoContentProps = {
  url: string;
};

export const VideoContent: React.FunctionComponent<VideoContentProps> = ({ url }) => {
  const clean = url.split('\n\n')[0];
  const videoId = clean.split('https://vimeo.com/')[1];
  const [isLoaded, setIsLoaded] = useState(false);
  const [shouldFade, setShouldFade] = useState(false);
  useEffect(() => {
    const to = setTimeout(() => setShouldFade(true), 250);
    return () => clearTimeout(to);
  }, []);
  return (
    <>
      <IFrameWrap className={classNames({ isActive: shouldFade })}>
        <img className={classNames('loader', { complete: isLoaded })} src={loadingAnimation} width={50} height={50} />
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <IFrame
            src={`https://player.vimeo.com/video/${
              videoId === '601139889' ? '459490037' : videoId
            }?h=7986fe3a77&autoplay=1&color=ff000a&title=0&byline=0&portrait=0`}
            //style={{}}
            //frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            isActive={isLoaded}
            onLoad={() => setIsLoaded(true)}
            isVideo
          />
        </div>
      </IFrameWrap>
    </>
  );
};

type PopupContentProps = {
  title: string;
  text: string;
  url?: string;
  onClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
  img: string;
  isMobile?: boolean;
};

export const PopupContent: React.FunctionComponent<PopupContentProps> = ({ text, url, onClose, img, isMobile, title }) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  //const content = text.split('\n').filter((i) => i !== '' && i.trim() !== 'Learn More>');
  //const title = content[0];
  //const subTitle = content.length > 2 ? content[1] : false;
  //const copy = content.length > 2 ? content[2] : content[1];

  const copy = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

  useEffect(() => {
    if (imageRef.current) {
      setWidth(imageRef.current.offsetWidth);
    }
  }, [imageRef]);

  useLayoutEffect(() => {
    const measure = () => {
      if (imageRef.current) {
        setWidth(imageRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  });

  const ratio = 720 / 520;
  const imgStyles = isMobile ? { position: 'relative' } : { position: 'relative', width: width, height: width * ratio };
  return (
    <Popup style={{ minHeight: width ? width * ratio : 'auto' }} isActive={!!width}>
      <PopupImage ref={imageRef}>
        <ImageLoader src={img} style={imgStyles as any}>
          <img src={img} width={'100%'} />
        </ImageLoader>
      </PopupImage>
      <PopupText>
        <PopupTitle>
          <span>{title}</span>
        </PopupTitle>
        {/* {!!subTitle && <PopupSubTitle>{subTitle}</PopupSubTitle>} */}
        <div dangerouslySetInnerHTML={{ __html: copy }} />

        {!!url && (
          <PopupLink href={url} target="_blank">
            Learn More &gt;
          </PopupLink>
        )}
      </PopupText>
      <PopupButtonWrap>
        <CloseButton onClick={onClose} className="close-item" />
      </PopupButtonWrap>
    </Popup>
  );
};

const IFrame = styled.iframe<{ isActive?: boolean; isVideo?: boolean }>`
  ${({ isVideo }) =>
    !!isVideo
      ? `
  position: absolute;
  top: 0;
  left: 0;
  height: 94vh;
  `
      : `
    height: 100%;
  `}
  width: 100%;
  border: 0;
  opacity: ${({ isActive }) => (!!isActive ? 1 : 0)};
  transition: opacity 0.35s ease-in-out;
`;

const Popup = styled.div<{ isActive?: boolean }>`
  width: 66%;
  background-color: ${colors.blueBox};
  position: relative;
  display: flex;
  opacity: ${({ isActive }) => (!!isActive ? 1 : 0)};
  transition: opacity 0.35s ease-in-out;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-direction: column;
  }
`;
const PopupImage = styled.div`
  width: 40%;
  //background-color: ${colors.blueBox};
  img {
    display: block;
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    width: 100%;
  }
`;
const HeadingVariant = styled.h3`
  font-size: 30px;
  line-height: 45px;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 12px;
  span {
    border-bottom: 4px solid ${colors.red};
    border-bottom-width: 0.25vw;
    border-bottom-width: clamp(2px, 0.25vw, 6px);

    margin-bottom: 4px;
    margin-bottom: 0.25vw;
    margin-bottom: clamp(2px, 0.25vw, 6px);
  }
`;

const PopupTitle = styled(HeadingVariant)`
  font-size: 1.55vw;
  line-height: 2.35vw;
  //display: block;
  margin-bottom: 30px;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    font-size: 4.5vw;
    line-height: 6.7vw;
    margin-bottom: 16px;
  }
`;
const PopupSubTitle = styled.strong`
  display: block;
  margin-bottom: 20px;
  font-style: italic;
`;
const PopupText = styled.div`
  width: 60%;
  background-color: ${colors.blueBox};
  padding: 30px 40px;
  color: ${colors.white};
  font-size: 16px;
  font-size: 0.9vw;
  line-height: 22px;
  line-height: 1.15vw;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    padding: 15px 20px;
    font-size: 3.4vw;
    line-height: 3.9vw;
    width: 100%;
  }
`;

const PopupLink = styled.a`
  color: ${colors.white};
  display: block;
  margin-top: 20px;
  font-weight: bold;
`;

const PopupButtonWrap = styled.div`
  position: absolute;
  top: 0;
  right: -56px;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    top: auto;
    bottom: 100px;
    right: 0;
    z-index: 99;
  }
`;
