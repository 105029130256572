import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Page: React.FunctionComponent = ({ children }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const to = setTimeout(() => setIsActive(true), 100);
    return () => clearTimeout(to);
  }, []);

  return <Container isActive={isActive}>{children}</Container>;
};

const Container = styled.div<{ isActive?: boolean }>`
  opacity: ${({ isActive }) => (!!isActive ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

export default Page;
