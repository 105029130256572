import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ScaledSubHeading, SubHeading } from 'components/Type';
import Carousel from 'components/Carousel';
import { ApiItem, ApiItemType, Item, ItemSize, ItemType } from 'components/Item/types';
import SubHeadingIcon from 'components/SubHeadingIcon';
import ViewAllButton from 'components/ViewAllButton';
import useIsDevice from 'hooks/useIsDevice';

type Props = {
  items: ApiItem[];
};

const FeaturedVideos: React.FunctionComponent<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  useLayoutEffect(() => {
    const measure = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };
    window.addEventListener('resize', measure);
    return () => window.removeEventListener('resize', measure);
  });

  const hasViewAll = props.items.length > 5;
  const heading = 'Featured Videos';

  const { isMobile, isTablet } = useIsDevice();
  const numItemsDisplay = isMobile ? (isTablet ? 2.5 : 1.5) : 5;

  return (
    <Container ref={ref}>
      <ScaledSubHeading marginTop={6}>
        {heading}
        <SubHeadingIcon type={ApiItemType.VIDEO} />
        {hasViewAll && <ViewAllButton items={props.items} title={heading} type={ApiItemType.VIDEO} hideIcons />}
      </ScaledSubHeading>

      <Carousel size={ItemSize.SMALL} width={width} items={props.items} hideIcons numItemsDisplay={numItemsDisplay} />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  margin-top: 10px;
  position: relative;
  min-width: 0;
`;

export default FeaturedVideos;
