import colors from 'constants/colors';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import menu from 'images/icon.menu.svg';
import search from 'images/icon.search.svg';
import back from 'images/icon.back.svg';
import close from 'images/icon.close.svg';
import logo from 'images/logo.l3.light.svg';

import { MenuState } from './types';
import { scaleBasedOnDesign } from 'helpers';
import { ApiCapability } from 'components/Capabilities/types';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import SearchBar from 'components/SearchBar';
import classNames from 'classnames';
import mobile from 'constants/mobile';
import useWindowSize from 'hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { selectCapabilityChildren, selectCoreCapabilities } from 'state/selectors';
import { MainState } from 'state/reducers';

type Props = {};

const Sidebar: React.FunctionComponent<Props> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuState, setMenuState] = useState(MenuState.CLOSED);
  const [hasScrolled, setHasScrolled] = useState(false);
  const coreCapabilities = useSelector(selectCoreCapabilities);

  const windowWidth = useWindowSize().width;

  const stateWidths = {
    [MenuState.CLOSED]: 0,
    [MenuState.OPEN_MENU]: scaleBasedOnDesign({ originalValue: 463, newWidth: windowWidth, minValue: 463 }),
    [MenuState.OPEN_SEARCH]: scaleBasedOnDesign({ originalValue: 690, newWidth: windowWidth, minValue: 690 }),
  };

  const navigate = useNavigate();
  const location = useLocation();

  const isTradeShow = location.pathname.indexOf('trade-show/') > -1;

  const onScroll = () => {
    const scrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
    setHasScrolled(scrollPosition > 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (menuState !== MenuState.CLOSED && ref.current) {
      //disableBodyScroll(ref.current);
      document.body.style.overflow = 'hidden';
      //document.body.style.height = '100vh';
    } else {
      //clearAllBodyScrollLocks();
      document.body.removeAttribute('style');
    }
  }, [menuState]);

  const onMenuPress = () => {
    setMenuState(MenuState.OPEN_MENU);
  };

  const onSearchPress = () => {
    setMenuState(MenuState.OPEN_SEARCH);
  };

  const onClosePress = () => {
    setMenuState(MenuState.CLOSED);
  };

  const shouldShowMenuIcon = (state: MenuState) => {
    switch (state) {
      case MenuState.OPEN_MENU:
        return false;
    }
    return true;
  };

  const shouldShowCloseIcon = (state: MenuState) => {
    switch (state) {
      case MenuState.CLOSED:
        return false;
    }
    return true;
  };

  const shouldShowSearchIcon = (state: MenuState) => {
    switch (state) {
      case MenuState.OPEN_SEARCH:
        return false;
    }
    return true;
  };

  const shouldShowBackButton = location.pathname !== '/';

  const onLogoPress = () => {
    navigate('/');
    setMenuState(MenuState.CLOSED);
  };
  const onBackPress = () => {
    navigate(-1);
    setMenuState(MenuState.CLOSED);
  };

  const onNavItemPressed = (cap: ApiCapability) => {
    navigate(cap.url);
    setMenuState(MenuState.CLOSED);
  };

  const onTradeShowsPress = () => {
    navigate(`/capabilities/trade-shows`);
    setMenuState(MenuState.CLOSED);
  };

  return (
    <Fixed>
      {/* <p style={{ color: 'white' }}>Device Width: {windowWidth}</p> */}
      <FixedBackground active={hasScrolled && !isTradeShow}>
        <a onClick={onLogoPress} style={{ pointerEvents: 'auto' }}>
          <Logo src={logo} width={195} height={56} />
        </a>

        <Container>
          {shouldShowMenuIcon(menuState) && <MenuItem src={menu} width={24} height={20} onClick={onMenuPress} />}
          {shouldShowCloseIcon(menuState) && <MenuItem src={close} width={24} height={24} onClick={onClosePress} />}
          {shouldShowSearchIcon(menuState) && <MenuItem src={search} width={26} height={25} onClick={onSearchPress} />}
          {/* {shouldShowBackButton && menuState === MenuState.CLOSED && (
            <MenuItem src={back} width={39} height={27.73} onClick={onBackPress} mobileOnly />
          )} */}
        </Container>
        {shouldShowBackButton && (
          <BackPosition>
            <MenuItem src={back} width={39} height={27.73} onClick={onBackPress} />
          </BackPosition>
        )}
      </FixedBackground>
      <MenuContainer
        //state={menuState}
        ref={ref}
        className={classNames('menu', { open: menuState !== MenuState.CLOSED })}
        style={{ width: stateWidths[menuState] }}
      >
        <MenuContent className={menuState} id="scroller">
          <ListItems isEnabled={menuState === MenuState.OPEN_MENU} hide={menuState === MenuState.OPEN_SEARCH}>
            {menuState === MenuState.OPEN_MENU && (
              <>
                <ListItem active={location.pathname === '/'}>
                  <a onClick={onLogoPress}>Home</a>
                </ListItem>

                {coreCapabilities
                  .filter((c) => c.urlTitle !== 'trade-shows')
                  .map((capability, idx) => (
                    <CapabilityNav key={`capnav-${idx}`} capability={capability} hasGap={idx > 0} onNavItemPressed={onNavItemPressed} />
                  ))}

                <ListItem hasGap active={location.pathname === `/capabilities/trade-shows`}>
                  <a onClick={onTradeShowsPress}>Trade shows</a>
                </ListItem>
              </>
            )}
          </ListItems>

          <SearchFade className={classNames({ isEnabled: menuState === MenuState.OPEN_SEARCH, hide: menuState !== MenuState.OPEN_SEARCH })}>
            {menuState === MenuState.OPEN_SEARCH && <SearchBar size={stateWidths[MenuState.OPEN_SEARCH]} />}
          </SearchFade>
        </MenuContent>
      </MenuContainer>
      {menuState !== MenuState.CLOSED && <Cover onClick={() => setMenuState(MenuState.CLOSED)} onTouchStart={(e) => e.preventDefault()} />}
    </Fixed>
  );
};

const CapabilityNav: React.FunctionComponent<{
  capability: ApiCapability;
  hasGap?: boolean;
  onNavItemPressed: (cap: ApiCapability) => void;
}> = ({ capability, hasGap, onNavItemPressed }) => {
  const children = useSelector((state: MainState) => selectCapabilityChildren(state, capability.tagId));
  const isNavActive = (cap: ApiCapability) => cap.url === location.pathname;
  const location = useLocation();
  return (
    <>
      <ListItem hasGap={hasGap}>{capability.label}</ListItem>
      {children.map((child) => (
        <ListItem key={`${capability.urlTitle}-${child.urlTitle}`} active={isNavActive(child)}>
          <a onClick={() => onNavItemPressed(child)}>{child.label}</a>
        </ListItem>
      ))}
    </>
  );
};

export default Sidebar;

const SearchFade = styled.div<{ isEnabled?: boolean; hide?: boolean }>`
  transition: opacity 0.25s 0.3s ease-in-out;
  opacity: 0;
  &.isEnabled {
    height: 100%;
    opacity: 1;
  }

  height: 1px;
  overflow: hidden;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    width: calc(100% - 30px);
  }
`;

const ListItems = styled.ul<{ isEnabled?: boolean; hide?: boolean }>`
  margin-bottom: 10px;
  margin-left: 20px;
  transition: opacity 0.25s 0.3s ease-in-out;
  opacity: ${({ isEnabled }) => (!!isEnabled ? 1 : 0)};
  ${({ hide }) =>
    !!hide &&
    `
    height: 1px;
    overflow: hidden;
    margin-bottom: 0;
  `}
  .open_menu & {
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      margin-bottom: 130px;
    }
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    margin-top: 30px;
    //margin-bottom: 50px;
  }
`;

const ListItem = styled.li<{ hasGap?: boolean; active?: boolean }>`
  font-size: 15px;
  font-size: 0.78vw;
  font-size: clamp(15px, 0.78vw, 20px);
  font-weight: 400;
  list-style-type: none;
  color: ${colors.navGrey};
  text-transform: uppercase;
  margin-bottom: 5px;
  width: 95%;
  position: relative;
  ${({ hasGap }) =>
    !!hasGap &&
    `
    margin-top: 26px;
  `}
  a {
    color: ${colors.white};
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      opacity: 0.7;
    }
  }
  ${({ active }) =>
    !!active &&
    `
    &:before {
      content: '';
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: ${colors.red};
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translateY(-50%);
    }
  `}
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    font-size: 3vw;
    font-size: clamp(13px, 3vw, 16px);
  }
`;

const Fixed = styled.div`
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    position: relative;
  }
`;
const FixedBackground = styled.div<{ active?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 145px;
  background-color: ${({ active }) => (!!active ? colors.customFade.darkGrey(1) : colors.customFade.darkGrey(0))};
  transition: background-color 0.4s ease-in;
  pointer-events: none;
  ${({ active }) =>
    !!active &&
    `
  //box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, 0.3);
  `}
  &:after {
    content: '';
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: box-shadow 0.4s linear;
    box-shadow: 0px 10px 10px -3px rgba(0, 0, 0, ${({ active }) => (!!active ? 0.3 : 0)});
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    position: relative;
    background-color: ${colors.customFade.darkGrey(1)};
    height: 70px;
    z-index: 11;
    &:after {
      display: none;
    }
  }
`;
const Container = styled.div`
  width: 60px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0 0 26px;
  z-index: 11;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    top: 0;
    right: 0;
    padding: 24px 26px 0 0;
    left: auto;
    flex-direction: row-reverse;
    width: auto;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
  }
`;

const MenuContainer = styled.div`
  overflow: hidden;
  background-color: ${colors.customFade.darkGreyGreen(0.8)};
  backdrop-filter: blur(30px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  transition: width 0.3s ease-in-out;
  pointer-events: auto;
  &.open_menu {
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    left: auto;
    right: 0;
    background-color: #191c1c;
    backdrop-filter: none;
    top: 68px;
    overflow: visible;
    overflow-y: auto;
    &.open {
      width: 100% !important;
    }
  }
  @-moz-document url-prefix() {
    background-color: ${colors.customFade.darkGreyGreen(1)};
  }
`;

const MenuContent = styled.div`
  margin-top: 150px;
  margin-left: 84px;
  //position: fixed;
  position: absolute;
  top: 0;
  bottom: 20px;
  left: 0;
  right: 90px;
  z-index: 10;
  overflow: hidden;
  &.open_menu {
    overflow-y: auto;
    //overflow: hidden;
    transform: translate3d(0, 0, 0);
    //padding-bottom: 100px;
    touch-action: pan-y;
  }
  pointer-events: none;
  .open & {
    pointer-events: auto;
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    //margin-top: 69px;
    margin-top: 0;
    margin-left: 30px;
    right: 30px;
    width: auto;
    position: static;
  }
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
`;

const MenuItem = styled.img<{ mobileOnly?: boolean }>`
  margin-bottom: 26px;
  cursor: pointer;
  pointer-events: auto;
  ${({ mobileOnly }) => !!mobileOnly && `display: none;`}
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: block;
    margin-left: 30px;
    margin-bottom: 0;
  }
`;
const Logo = styled.img`
  position: absolute;
  z-index: 11;
  top: 30px;
  left: 104px;
  pointer-events: auto;

  @media (max-width: ${mobile.MAX_WIDTH}px) {
    top: 7px;
    left: 20px;
    width: 150px;
  }
`;

const BackPosition = styled.div`
  position: absolute;
  top: 39px; //86px;
  right: 20px;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    display: none;
  }
`;
