import React, { useEffect } from 'react';
import styled from 'styled-components';

import colors from 'constants/colors';
import Sidebar from 'components/Sidebar';
import HomePage from 'pages/HomePage';
import { Route, Routes } from 'react-router-dom';
import CapabilityPage from 'pages/CapabilityPage';
import CapabilityChildPage from 'pages/CapabilityChildPage';
import TradeShowPage from 'pages/TradeShowPage';
import TradeShowCapabilityPage from 'pages/TradeShowCapabilityPage';
import mobile from 'constants/mobile';
import { useDispatch } from 'react-redux';
import { getCapabilities, getHeaders, getLayouts, getTiles, getTradeShows } from 'state/actions';
import TradeShowLandingPage from 'pages/TradeShowLandingPage';
import queryString from 'query-string';

const STAGING_STORAGE_KEY = 'isStaging';

const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const qs = queryString.parse(window.location.search);

  if (!!qs.staging) {
    localStorage.setItem(STAGING_STORAGE_KEY, 'true');
  }

  const isStaging = !!qs.staging ? true : !!localStorage.getItem(STAGING_STORAGE_KEY);

  const onExitStaging = () => {
    localStorage.removeItem(STAGING_STORAGE_KEY);
    window.location.href = '/';
  };

  useEffect(() => {
    dispatch(getTiles.request({ isStaging }));
    dispatch(getCapabilities.request({ isStaging }));
    dispatch(getLayouts.request({ isStaging }));
    dispatch(getHeaders.request({ isStaging }));
    dispatch(getTradeShows.request({ isStaging }));
  }, []);

  return (
    <AppContainer>
      <Sidebar />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/capabilities/:capabilityId" element={<CapabilityPage />} />
        <Route path="/capabilities/:capabilityId/:capabilityChildId" element={<CapabilityChildPage />} />
        <Route path="/trade-show/:tradeShowId" element={<TradeShowPage />} />
        <Route path="/trade-show/:tradeShowId/all" element={<TradeShowLandingPage />} />
        <Route path="/trade-show/:tradeShowId/:tradeShowCapabilityId" element={<TradeShowCapabilityPage />} />
      </Routes>

      {isStaging && <StagingBar onClick={onExitStaging}>STAGING (CLICK TO EXIT)</StagingBar>}
    </AppContainer>
  );
};

const AppContainer = styled.div`
  background-color: ${colors.background};
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    width: 100vw;
  }
`;

export const Content = styled.div`
  padding: 0 105px;
  padding-bottom: 50px;
  &.wideOnly {
    padding-bottom: 0px;
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      padding: 0;
    }
  }
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    padding: 0 20px;
  }
`;

const StagingBar = styled.div`
  //position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 25px;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.25s ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;

export default App;
