import classNames from 'classnames';
import colors from 'constants/colors';
import mobile from 'constants/mobile';
import tablet from 'constants/tablet';
import { scaleBasedOnDesign } from 'helpers/index';
import useWindowSize from 'hooks/useWindowSize';
import styled from 'styled-components';

// 300 - light
// 400 - regular
// 500 - medium
// 600 - semi bold
// 700 - bold

export const ScaledHeading: React.FunctionComponent<{ content: string; noBottomMargin?: boolean }> = ({
  content,
  children,
  noBottomMargin,
}) => {
  const windowWidth = useWindowSize().width;

  const fontSize = scaleBasedOnDesign({ originalValue: 32, minValue: 22, newWidth: windowWidth });
  const lineHeight = scaleBasedOnDesign({ originalValue: 37, minValue: 24, newWidth: windowWidth });

  return (
    <Heading
      style={{ fontSize, lineHeight: `${lineHeight}px` }}
      dangerouslySetInnerHTML={{ __html: content }}
      className={classNames({ noMarg: noBottomMargin })}
    />
  );
};

export const ScaledParagraph: React.FunctionComponent<{ content: string; noMobile?: boolean }> = ({ content, noMobile, children }) => {
  const windowWidth = useWindowSize().width;

  const fontSize = scaleBasedOnDesign({ originalValue: 16, minValue: 14, newWidth: windowWidth });
  const lineHeight = scaleBasedOnDesign({ originalValue: 21, minValue: 17, newWidth: windowWidth });

  return (
    <Paragraph
      className={classNames({ noMobile })}
      style={{ fontSize, lineHeight: `${lineHeight}px` }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export const ScaledSecondaryLabel: React.FunctionComponent<{ content: string }> = ({ content, children }) => {
  const windowWidth = useWindowSize().width;

  const fontSize = scaleBasedOnDesign({ originalValue: 16, minValue: 14, newWidth: windowWidth });
  const lineHeight = scaleBasedOnDesign({ originalValue: 21, minValue: 17, newWidth: windowWidth });
  const marginBottom = scaleBasedOnDesign({ originalValue: 10, minValue: 6, newWidth: windowWidth });

  return <SecondaryLabel style={{ fontSize, lineHeight: `${lineHeight}px`, marginBottom }} dangerouslySetInnerHTML={{ __html: content }} />;
};

export const ScaledHeadingUnderlined: React.FunctionComponent<{ content: string }> = ({ content }) => {
  const windowWidth = useWindowSize().width;

  const fontSize = scaleBasedOnDesign({ originalValue: 30, minValue: 22, newWidth: windowWidth });
  const lineHeight = scaleBasedOnDesign({ originalValue: 45, minValue: 35, newWidth: windowWidth });
  const marginBottom = scaleBasedOnDesign({ originalValue: 26, minValue: 10, newWidth: windowWidth });
  const bottomBorder = scaleBasedOnDesign({ originalValue: 4, minValue: 2, newWidth: windowWidth });

  return (
    <HeadingUnderlined style={{ fontSize, lineHeight: `${lineHeight}px`, marginBottom }}>
      <span style={{ borderBottomWidth: bottomBorder }}>{content}</span>
    </HeadingUnderlined>
  );
};

export const ScaledSubHeading: React.FunctionComponent<{ marginTop?: number }> = ({ children, marginTop }) => {
  // const windowWidth = useWindowSize().width;

  // const fontSize = scaleBasedOnDesign({ originalValue: 20, minValue: 16, newWidth: windowWidth });

  // return <SubHeading style={{ fontSize, marginTop: !!marginTop ? marginTop : 0 }}>{children}</SubHeading>;
  return <SubHeading style={{ marginTop: !!marginTop ? marginTop : 0 }}>{children}</SubHeading>;
};

export const ScaledLabel: React.FunctionComponent<{ content: string }> = ({ content }) => {
  //const windowWidth = useWindowSize().width;

  //const fontSize = scaleBasedOnDesign({ originalValue: 35, minValue: 22, newWidth: windowWidth });
  //const lineHeight = scaleBasedOnDesign({ originalValue: 50, minValue: 24, newWidth: windowWidth });

  return (
    //<LabelStyled style={{ fontSize, lineHeight: `${lineHeight}px` }}>
    <LabelStyled>
      <span>{content}</span>
    </LabelStyled>
  );
};

export const Heading = styled.h1`
  color: ${colors.white};
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 35px;
  line-height: 37px;
  font-weight: 600;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    font-size: 7vw;
    line-height: 7.5vw;
  }
  &.noMarg {
    @media (max-width: ${mobile.MAX_WIDTH}px) {
      margin-bottom: 0;
    }
  }
`;

export const SubHeading = styled.h2`
  //font-size: 20px;
  font-size: 1.04vw;
  font-size: clamp(14px, 1.04vw, 30px);
  font-weight: 600;

  color: ${colors.white};
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: 'IBM Plex Sans', sans-serif;
  @media (max-width: ${mobile.MAX_WIDTH}px) {
    font-size: 3.2vw;
    font-size: clamp(14px, 3.2vw, 16px);
  }
`;

export const Paragraph = styled.p`
  color: ${colors.white};
  margin-bottom: 10px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
`;

export const Label: React.FunctionComponent = ({ children }) => (
  <LabelStyled>
    <span>{children}</span>
  </LabelStyled>
);

export const LabelStyled = styled.h3`
  // font-size: 35px;
  // line-height: 50px;
  font-size: 1.97vw;
  line-height: 2.95vw;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  span {
    border-bottom: 4px solid ${colors.red};
    border-bottom-width: 0.25vw;
    border-bottom-width: clamp(2px, 0.25vw, 6px);
    @media (max-width: ${tablet.MAX_WIDTH}px) {
      border-bottom-width: 0.35vw;
      border-bottom-width: clamp(2px, 0.35vw, 6px);
    }
    @media (max-width: ${tablet.MIN_WIDTH}px) {
      border-bottom-width: 0.55vw;
      border-bottom-width: clamp(2px, 0.55vw, 6px);
    }
  }

  @media (max-width: ${tablet.MAX_WIDTH}px) {
    font-size: 3.2vw;
    line-height: 5vw;
  }
  @media (max-width: ${tablet.MIN_WIDTH}px) {
    font-size: 5vw;
    line-height: 8vw;
  }
`;

export const SecondaryLabel = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: 'IBM Plex Sans', sans-serif;
  display: block;
  margin-bottom: 10px;
`;

export const HeadingUnderlined = styled.h3`
  font-size: 30px;
  line-height: 45px;
  color: ${colors.white};
  text-transform: uppercase;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 26px;
  span {
    border-bottom: 4px solid ${colors.red};
    margin-bottom: 4px;
  }
`;
